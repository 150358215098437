import React from "react";
import logo from "../../images/coa-logo-white.svg";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "./Header.scss";

function Header(props) {
  return (
    <>
      <MDBRow className="top-header">
        <MDBCol>
          <MDBContainer>
            <div>
              <Link to={"/"} className="header-logo nav-link">
                <img className="logo" src={logo} alt="State Government Logo" />
              </Link>
            </div>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default Header;
