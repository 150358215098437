let _validateObjectorHelper = {
  councilId: {
    required: true,
    hasError: false,
    helperText: "Select the LGA your property is located within",
    type: "select",
    fieldName: "Council",
    errorText: "",
  },
  assessmentNumber: {
    required: true,
    hasError: false,
    helperText: "Council Assessment Number of Property",
    type: "fixedLength",
    fieldName: "Assessment Number",
    errorText: "",
    length: 10
  },
};

class ValidateObjectorHelper {
  getValidateObjectorHelper() {
    return _validateObjectorHelper;
  }

  getFieldDetail(field) {
    let value = _validateObjectorHelper[field];
    return value;
  }
}

// we need to instantiate the store
const validateObjectorHelper = new ValidateObjectorHelper();

export default validateObjectorHelper;
