//#region imports
import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import CommonStore from "../../stores/commonStore";
import eraBuilt from "../../Lists/EraBuilt";
import propertyTypes from "../../Lists/PropertyTypes";
import constructionMaterials from "../../Lists/ConstructionMaterials";
import dwellingTypes from "../../Lists/DwellingTypes";
import MDBTextArea from "../../controls/MDBReact-InputTextArea";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import AirConditionings from "../../Lists/AirConditionings";
import YesNoList from "../../Lists/YesNo";
import MaterialRadio from "../../controls/MaterialUI-Radio";
import MaterialSelect from "../../controls/MaterialUI-Select";
import PropertyConditions from "../../Lists/PropertyConditions";
import { isEmailLink } from "../../utilities/isEmailLink";
import PropertyLeaseDetails from "./PropertyLeaseDetails/PropertyLeaseDetails";
import "./PropertyDetails.scss";
import TenancyInformation from "../TenancyInformation/TenancyInformation";
//#endregion imports

function PropertyDetails(props) {
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }

  //#endregion verifying the loading of this component
  const Objection_Reason_Land_Not_Correct = "2";
  const [propertyDetailsText] = useState(CommonStore.getPropertyDetailsText());
  const [property, setProperty] = useState(props.property);
  const [objectionDetailsHelper, setObjectionDetailsHelper] = useState(
    props.objectionDetailsHelper
  );
  const PropertyType_Residential = "4";
  const PropertyType_Commercial = "2";
  //#endregion Component variables

  //#region UseEffect Hook
  useEffect(() => {
    setProperty(props.property);
    setObjectionDetailsHelper(props.objectionDetailsHelper);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.property]);
  //#endregion UseEffect Hook

  return (
    <>
      <MDBRow className="property-details-background mb-3">
        <MDBCol>
          <div
            className="cms-content-property-details"
            dangerouslySetInnerHTML={{ __html: propertyDetailsText }}
          ></div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Property type"
            name="propertyTypeId"
            value={property.propertyTypeId}
            onSelectChange={props.handleChange}
            list={propertyTypes}
            formHelperText={objectionDetailsHelper.propertyTypeId.errorText}
            disabled={false}
            required={objectionDetailsHelper.propertyTypeId.required}
            error={objectionDetailsHelper.propertyTypeId.hasError}
          />
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Gross floor area (m²)"
            name="buildAreaM2"
            value={property.buildAreaM2}
            onChange={props.handleChange}
            disabled={false}
            required={objectionDetailsHelper.buildAreaM2.required}
            hasError={objectionDetailsHelper.buildAreaM2.hasError}
            helperText={objectionDetailsHelper.buildAreaM2.errorText}
            onBlur={props.onBlur}
          ></MaterialInputText>
        </MDBCol>
      </MDBRow>

      {property.propertyTypeId === PropertyType_Commercial && (
        <>
          <MDBRow>
            <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
              <MaterialInputText
                label="Net lettable area"
                name="netLettableArea"
                value={property.netLettableArea}
                onChange={props.handleChange}
                disabled={false}
                helperText={objectionDetailsHelper.netLettableArea.errorText}
                required={objectionDetailsHelper.netLettableArea.required}
                hasError={objectionDetailsHelper.netLettableArea.hasError}
                onBlur={props.onBlur}
              ></MaterialInputText>
            </MDBCol>
            <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
              <MaterialInputText
                label="Car parks"
                name="carParks"
                value={property.carParks}
                onChange={props.handleChange}
                disabled={false}
                helperText={objectionDetailsHelper.carParks.errorText}
                required={objectionDetailsHelper.carParks.required}
                hasError={objectionDetailsHelper.carParks.hasError}
                onBlur={props.onBlur}
              ></MaterialInputText>
            </MDBCol>
          </MDBRow>
        </>
      )}
      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Improvement type"
            name="dwellingTypeId"
            value={property.dwellingTypeId}
            onSelectChange={props.handleChange}
            list={dwellingTypes}
            formHelperText={objectionDetailsHelper.dwellingTypeId.errorText}
            disabled={false}
            required={objectionDetailsHelper.dwellingTypeId.required}
            error={objectionDetailsHelper.dwellingTypeId.hasError}
          ></MaterialSelect>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Construction material"
            name="constructionMaterialId"
            value={property.constructionMaterialId}
            onSelectChange={props.handleChange}
            list={constructionMaterials}
            formHelperText={
              objectionDetailsHelper.constructionMaterialId.errorText
            }
            disabled={false}
            required={objectionDetailsHelper.constructionMaterialId.required}
            error={objectionDetailsHelper.constructionMaterialId.hasError}
          ></MaterialSelect>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Era built"
            name="yearBuilt"
            value={property.yearBuilt}
            onSelectChange={props.handleChange}
            list={eraBuilt}
            formHelperText={objectionDetailsHelper.yearBuilt.errorText}
            disabled={false}
            required={objectionDetailsHelper.yearBuilt.required}
            error={objectionDetailsHelper.yearBuilt.hasError}
            onBlur={props.onBlur}
          ></MaterialSelect>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Building condition"
            name="buildingConditionId"
            value={property.buildingConditionId}
            onSelectChange={props.handleChange}
            list={PropertyConditions}
            formHelperText={
              objectionDetailsHelper.constructionMaterialId.helperText
            }
            disabled={false}
            required={objectionDetailsHelper.constructionMaterialId.required}
            error={objectionDetailsHelper.constructionMaterialId.hasError}
          ></MaterialSelect>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Renovation year"
            name="yearRenovation"
            value={property.yearRenovation}
            onChange={props.handleChange}
            disabled={false}
            helperText={objectionDetailsHelper.yearRenovation.errorText}
            required={objectionDetailsHelper.yearRenovation.required}
            hasError={objectionDetailsHelper.yearRenovation.hasError}
            onBlur={props.onBlur}
          ></MaterialInputText>
        </MDBCol>
        {property.propertyTypeId === PropertyType_Residential && (
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialInputText
              label="Number of main rooms"
              name="numberRooms"
              value={property.numberRooms}
              onChange={props.handleChange}
              disabled={false}
              helperText={objectionDetailsHelper.numberRooms.errorText}
              required={objectionDetailsHelper.numberRooms.required}
              hasError={objectionDetailsHelper.numberRooms.hasError}
              onBlur={props.onBlur}
            ></MaterialInputText>
          </MDBCol>
        )}
      </MDBRow>

      {property.propertyTypeId === PropertyType_Residential && (
        <>
          <MDBRow>
            <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
              <MaterialInputText
                label="Bedrooms"
                name="numberBedrooms"
                value={property.numberBedrooms}
                onChange={props.handleChange}
                disabled={false}
                helperText={objectionDetailsHelper.numberBedrooms.errorText}
                required={objectionDetailsHelper.numberBedrooms.required}
                hasError={objectionDetailsHelper.numberBedrooms.hasError}
                onBlur={props.onBlur}
              ></MaterialInputText>
            </MDBCol>
            <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
              <MaterialInputText
                label="Bathrooms"
                name="numberBathrooms"
                value={property.numberBathrooms}
                onChange={props.handleChange}
                disabled={false}
                helperText={objectionDetailsHelper.numberBathrooms.errorText}
                required={objectionDetailsHelper.numberBathrooms.required}
                hasError={objectionDetailsHelper.numberBathrooms.hasError}
                onBlur={props.onBlur}
              ></MaterialInputText>
            </MDBCol>
          </MDBRow>
        </>
      )}
      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialRadio
            label="Air conditioning"
            list={AirConditionings}
            name="airConditioningId"
            value={property.airConditioningId}
            aria-required={true}
            radioChanged={props.radioChanged}
            required={objectionDetailsHelper.airConditioningId.required}
            error={objectionDetailsHelper.airConditioningId.hasError}
            helperText={objectionDetailsHelper.airConditioningId.errorText}
            onBlur={props.onBlur}
          ></MaterialRadio>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialRadio
            label="Elevator in building"
            list={YesNoList}
            name="elevatorInBuilding"
            value={property.elevatorInBuilding}
            aria-required={true}
            radioChanged={props.radioChanged}
            required={objectionDetailsHelper.elevatorInBuilding.required}
            error={objectionDetailsHelper.elevatorInBuilding.hasError}
            helperText={objectionDetailsHelper.elevatorInBuilding.errorText}
            onBlur={props.onBlur}
          ></MaterialRadio>
        </MDBCol>
      </MDBRow>

      <MDBRow className="mb-3">
        <MDBCol>
          Please provide any extra information below to assist the valuer in
          their review:
        </MDBCol>
      </MDBRow>

      <MDBRow className="mb-3">
        <MDBCol>
          <MDBTextArea
            label="Additional property information"
            name="dwellingDescription"
            value={property.dwellingDescription}
            onChange={props.handleChange}
            disabled={false}
            hasError={objectionDetailsHelper.dwellingDescription.hasError}
            required={objectionDetailsHelper.dwellingDescription.required}
            helperText={objectionDetailsHelper.dwellingDescription.errorText}
          ></MDBTextArea>
        </MDBCol>
      </MDBRow>

      {property.propertyTypeId !== PropertyType_Residential && (
        <PropertyLeaseDetails
          property={property}
          onBlur={props.onBlur}
          objectionDetailsHelper={objectionDetailsHelper}
          handleChange={props.handleChange}
          onDateChange={props.onDateChange}
        ></PropertyLeaseDetails>
      )}

      {property.objectionReasonId !== Objection_Reason_Land_Not_Correct && (
        <TenancyInformation
          property={props.property}
          objector={props.objector}
          onBlur={props.onBlur}
          objectionDetailsHelper={objectionDetailsHelper}
          handleChange={props.handleChange}
          radioChanged={props.radioChanged}
          handleAttachmentUploaded={props.handleAttachmentUploaded}
          attachmentDeleted={props.attachmentDeleted}
        ></TenancyInformation>
      )}
    </>
  );
}

export default PropertyDetails;
