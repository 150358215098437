import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { getNamefromList } from "../../../utilities/getNameFromList";
import { valueCheck } from "../../../utilities/valueCheck";
import { dateFormat } from "../../../utilities/dateFormat";
import reviewFrequencies from "../../../Lists/ReviewFrequencies";
import leaseTerms from "../../../Lists/LeaseTerms";
import reviewBasis from "../../../Lists/ReviewBasis";

function ReviewLeaseDetails(props) {
  const property = props.property;
  const Not_Entered_Amount = "0";
  const Not_Selected_Value = "-1";
  const Objection_Reason_Land_Not_Correct = "2";
  const showLeaseTerm = valueCheck(property.leaseTerm, true, true, false, null);

  const showLeaseCommenced = valueCheck(
    property.leaseCommenced,
    true,
    true,
    false,
    null
  );
  const showLeaseAmount = valueCheck(
    property.leaseAmountPA,
    true,
    true,
    true,
    Not_Entered_Amount
  );

  const showOptions = valueCheck(property.options, true, true, false, null);

  const showReviewFrequency = valueCheck(
    property.reviewFrequency,
    true,
    true,
    true,
    Not_Selected_Value
  );

  const showReviewBasis = valueCheck(
    property.reviewBasis,
    true,
    true,
    true,
    Not_Selected_Value
  );

  const showTenantOutgoings = valueCheck(
    property.tenantOutgoings,
    true,
    true,
    false,
    null
  );

  const showOwnerOutgoings = valueCheck(
    property.ownerOutgoings,
    true,
    true,
    false,
    null
  );

  const showReviewDetail = valueCheck(
    property.reviewDetail,
    true,
    true,
    false,
    null
  );

  const showTisPreviouslyFilled = valueCheck(
    property.tisPreviouslyFilled,
    true,
    true,
    false,
    null
  );

  const showYearTisSupplied = valueCheck(
    property.yearTenancyInformationScheduleSupplied,
    true,
    true,
    false,
    null
  );

  const showLeaseDetailComponent =
    (showLeaseTerm ||
      showLeaseCommenced ||
      showLeaseAmount ||
      showOptions ||
      showReviewFrequency ||
      showReviewBasis ||
      showTenantOutgoings ||
      showOwnerOutgoings ||
      showReviewDetail ||
      showTisPreviouslyFilled ||
      showYearTisSupplied) &&
    property.objectionReasonId !== Objection_Reason_Land_Not_Correct;
  // since this entire component is not required
  // for objection reason land use not correct

  return (
    <>
      {showLeaseDetailComponent && (
        <div>
          <MDBRow className="mt-3 mb-3">
            <MDBCol className="review-section-headings">Lease details</MDBCol>
          </MDBRow>

          {(showLeaseTerm || showLeaseCommenced) && (
            <>
              <MDBRow>
                {showLeaseTerm && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Lease term
                      </span>
                      <span className="review-page-field-value">
                        {getNamefromList(
                          leaseTerms,
                          property.leaseTerm.toString()
                        )}
                      </span>
                    </div>
                  </MDBCol>
                )}
                {showLeaseCommenced && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Lease commencement
                      </span>
                      <span className="review-page-field-value">
                        {dateFormat(property.leaseCommenced, "dd/mm/yyyy")}
                      </span>
                    </div>
                  </MDBCol>
                )}
              </MDBRow>
            </>
          )}

          {(showLeaseAmount || showOptions) && (
            <>
              <MDBRow>
                {showLeaseAmount && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Lease amount per year
                      </span>
                      <span className="review-page-field-value">
                        {"$" + property.leaseAmountPA}
                      </span>
                    </div>
                  </MDBCol>
                )}
                {showOptions && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Lease renewal options
                      </span>
                      <span className="review-page-field-value">
                        {property.options}
                      </span>
                    </div>
                  </MDBCol>
                )}
              </MDBRow>
            </>
          )}

          {(showReviewFrequency || showReviewBasis) && (
            <>
              <MDBRow>
                {showReviewFrequency && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Review frequency
                      </span>
                      <span className="review-page-field-value">
                        {getNamefromList(
                          reviewFrequencies,
                          property.reviewFrequency.toString()
                        )}
                      </span>
                    </div>
                  </MDBCol>
                )}
                {showReviewBasis && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Review basis
                      </span>
                      <span className="review-page-field-value">
                        {getNamefromList(
                          reviewBasis,
                          property.reviewBasis.toString()
                        )}
                      </span>
                    </div>
                  </MDBCol>
                )}
              </MDBRow>
            </>
          )}

          {(showTenantOutgoings || showOwnerOutgoings) && (
            <>
              <MDBRow>
                {showTenantOutgoings && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Outgoings (Tenant) per year
                      </span>
                      <span className="review-page-field-value">
                        {property.tenantOutgoings}
                      </span>
                    </div>
                  </MDBCol>
                )}
                {showOwnerOutgoings && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Outgoings (Owner) per year
                      </span>
                      <span className="review-page-field-value">
                        {property.ownerOutgoings}
                      </span>
                    </div>
                  </MDBCol>
                )}
              </MDBRow>
            </>
          )}

          {(showTisPreviouslyFilled || showYearTisSupplied) && (
            <>
              <MDBRow className="mb-3">
                <MDBCol>
                  <div className="review-page-field">
                    <span className="review-page-field-heading">
                      Previously provided Tenancy information schedule (TIS)
                    </span>
                    <span className="review-page-field-value">
                      {property.objectionReasonId !==
                        Objection_Reason_Land_Not_Correct &&
                        property.tisPreviouslyFilled === "1" && (
                          <>
                            Yes
                            {" - " +
                              property.yearTenancyInformationScheduleSupplied}
                          </>
                        )}
                      {property.objectionReasonId !==
                        Objection_Reason_Land_Not_Correct &&
                        property.tisPreviouslyFilled === "2" && (
                          <>No {" - " + property.tisAttachments}</>
                        )}
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          )}

          {showReviewDetail && (
            <>
              <MDBRow className="mb-3">
                <MDBCol>
                  <div className="review-page-field">
                    <span className="review-page-field-heading">
                      Lease comments
                    </span>
                    <span className="review-page-field-value">
                      {property.reviewDetail}
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ReviewLeaseDetails;
