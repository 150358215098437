//#region import
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import PropertyStore from "../../stores/propertyStore";
import ObjectionStore from "../../stores/objectionStore";
import CommonStore from "../../stores/commonStore";
import MaterialInputCurrency from "../../controls/MaterialUI-InputCurrency";
import MDBTextArea from "../../controls/MDBReact-InputTextArea";
import * as PropertyActions from "../../actions/propertyActions";
import * as ObjectionActions from "../../actions/objectionActions";
import * as BreadcrumbActions from "../../actions/breadcrumbActions";
import ObjectionDetailsHelper from "../../FieldHelper/objectionDetailsHelper";
import FormatCurrency from "../../utilities/currencyformatter";
import {
  FocusOnErrorControl,
  ScrollTo,
} from "../../utilities/focusErrorControl";
import constants from "../Constants";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import {
  fieldValidator,
  fieldLevelValidation,
} from "../../utilities/fieldValidator";
import ObjectorStore from "../../stores/objectorStore";
import { getNamefromList } from "../../utilities/getNameFromList";
import Councils from "../../Lists/Councils";
import objectionReasons from "../../Lists/ObjectionReasons";
import MaterialRadio from "../../controls/MaterialUI-Radio";
import Steppers from "../../controls/Steppers/Steppers";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import { isEmailLink } from "../../utilities/isEmailLink";
import PropertyDetails from "../PropertyDetails/PropertyDetails";
import MaterialSelect from "../../controls/MaterialUI-Select";
import propertyCategories from "../../Lists/PropertyCategories";
import MaterialAutoComplete from "../../controls/MaterialUI-AutoComplete";
import Attachments from "../Attachments/Attachments";
import Constants from "../Constants";
import "./ObjectionDetails.scss";
//#endregion imports

function ObjectionDetails(props) {
  //#region email link check
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }
  //#endregion email link check

  //#region verifying if the loading of this component was using
  // routing or Did user entered a URL and opened this page
  var objector = ObjectorStore.getObjector();
  var propertyId = null;
  if (!objector || !objector.objectorId) {
    props.history &&
      props.history.push({
        pathname: "/ObjectorDetails",
      });
  } else {
    // we came here means, we do have an Objector in flux / session
    // now check if we have a propertyId
    if (props && props.location && props.location.propertyId && props.location.propertyId !== '0') {
      propertyId = props.location.propertyId;
    } else {
      props.history &&
        props.history.push({
          pathname: "/PropertyIdentification",
          objectorId: objector.objectorId,
        });
    }
  }
  //#endregion verifying the loading of this component

  //#region component declarations
  const Field_TIS_PREVIOUSLY_FILLED = "tisPreviouslyFilled";
  const Field_Objection_Reason = "objectionReasonId";
  const Field_Blank_Contended_Value = "hasBlankValues";
  const Field_Displayed_Acceptance_Checked = "displayedAcceptenceCheck";
  const Field_TIS_Attachments = "tisAttachments";
  const Field_Year_TIS_Submitted = "yearTenancyInformationScheduleSupplied";
  const Objection_Reason_Land_Not_Correct = "2";
  const TIS_FILLED_YES = "1";
  const TIS_FILLED_NO = "2";
  const Rental_Evidence = 4;
  const PropertyType_Residential = "4";
  const PropertyType_Commercial = "2";
  const Max_Files_Property = 3;

  const [blankContendedValues, setBlankContendedValues] = useState(
    PropertyStore.getBlankContendedValues(propertyId)
  );
  const [blankValuesError, setBlankValuesError] = useState(false);
  const [objectingToValuesText] = useState(
    CommonStore.getObjectingToValuesText()
  );
  const [objectingToValuesWarning] = useState(
    CommonStore.getObjectingToValuesWarning()
  );
  const [objectionCommentsText] = useState(
    CommonStore.getObjectionCommentsText()
  );
  const [property, setProperty] = useState(
    PropertyStore.getPropertyById(propertyId)
  );
  const [objectionDetailsHelper, setObjectionDetailsHelper] = useState(
    ObjectionStore.getObjectionDetailsHelper()
  );
  //#endregion component declaration

  //#region useEffect State hook
  useEffect(() => {
    PropertyStore.addChangeListener(onchange);

    document.title = "Object to Rating Valuation - Grounds for Objecting";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // since by default TIS fields are not required, so checking if he have a value for tisPreviouslyFilled. if its NO, then making handler fields required
    updateTisHelper(
      property.tisPreviouslyFilled,
      property.objectionReasonId !== "" &&
        property.objectionReasonId !== Objection_Reason_Land_Not_Correct
    );

    // initializing the breadcrumbs update the breadcrumbs/stepper to hide Rental Evidence For adelaide if objectionReasonId is Land_Not_Correct
    BreadcrumbActions.changeRouteVisibility(
      constants.BREADCRUMB_RENTAL,
      property &&
        property.objectionReasonId === Objection_Reason_Land_Not_Correct
        ? false
        : true
    );

    function onchange() {
      setProperty(PropertyStore.getPropertyById(propertyId));
    }

    return () => PropertyStore.removeChangeListener(onchange); //cleans up on unmount of component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region useEffect State Hook
  useEffect(() => {
    ObjectionStore.addChangeListener(onHelperChange);

    function onHelperChange() {
      setObjectionDetailsHelper(ObjectionStore.getObjectionDetailsHelper());
    }
    return () => ObjectionStore.removeChangeListener(onHelperChange); //cleans up on unmount of component
  }, [objectionDetailsHelper]);
  //#endregion useEffect State hook

  //#region API calling methods
  function saveObjectionDetails() {
    startLoader();

    let isFieldValidationSuccess = fieldValidator(
      constants.OBJECTION,
      property,
      objectionDetailsHelper
    );

    if (!isFieldValidationSuccess) {
      PropertyActions.dispatchUpdateProperty(property);
      setTimeout(() => {
        ObjectionActions.updateObjectionDetailsHelper(objectionDetailsHelper);
        stopLoader();
      }, 100);
      FocusOnErrorControl(objectionDetailsHelper);

      return;
    }

    if (!contendedValueValid()) {
      stopLoader();
      return;
    }

    PropertyActions.updateObjectionDetailsOfProperty(
      property,
      objector.objectorId
    ).then((response) => {
      PropertyActions.dispatchUpdateProperty(property);

      setTimeout(() => {
        stopLoader();
        // we need to check if, the objection reason selected is
        // Objection_Reason_Land_Not_Correct !== "2", if yes, go to Rental evidence
        let nextRoute = "/ReviewSubmission";
        if (property.objectionReasonId !== Objection_Reason_Land_Not_Correct) {
          nextRoute = "/RentalEvidence";
        }

        props.history &&
          props.history.push({
            pathname: nextRoute,
            propertyId: property.propertyId,
            objectorId: objector.objectorId,
          });
        ObjectionActions.updateObjectionDetailsHelper(objectionDetailsHelper);
      }, 1500);
    });
  }
  //#endregion API calling method

  //#region helper functions

  function contendedValueValid() {
    let isContendedValueValid = true;
    // For Adelaide - we only need contended AAV value if user selected
    // objectionReason === Objection_Reason_AAV_High otherwise, we do not need to check this
    if (property.objectionReasonId !== Objection_Reason_Land_Not_Correct) {
      // if all three contended values are left blank we need to turn on the
      // check box, and make user click it to process
      if (property.contendedNetAnnualValue === "") {
        // the showBlankValueWarning() will handle the Blank contended value section.
        // if it returns false, means we can not save objection, and return to the user
        if (!showBlankValueWarning()) {
          let element = document.getElementById("obj-details-cnav");
          if (element) {
            ScrollTo(element);
          }
          isContendedValueValid = false;
        }
      }
    }
    return isContendedValueValid;
  }

  /* the purpose of this method is to implement a special requirement
  If user can see the CONTENDED VALUE and does not enter it, We have to
  show a warning */
  function showBlankValueWarning() {
    let blankValuesHandled = blankContendedValues.blankValuesHandled;
    let hasBlankValue = true; // since entering this method is due to a blank value
    let checkAlreadyOnDisplay = blankContendedValues.displayedAcceptenceCheck;
    let userAccepted = blankContendedValues.userAccepted;

    setBlankContendedValues({
      ...blankContendedValues,
      [Field_Blank_Contended_Value]: hasBlankValue,
    });

    // check if the Warning checkbox is Already displayed in DOM
    if (!blankContendedValues.displayedAcceptenceCheck) {
      checkAlreadyOnDisplay = true;
      setBlankContendedValues({
        ...blankContendedValues,
        [Field_Displayed_Acceptance_Checked]: checkAlreadyOnDisplay,
      });

      blankValuesHandled = false;
    } else {
      if (!blankContendedValues.accepted) {
        setBlankValuesError(true);
        blankValuesHandled = false;
      } else {
        setBlankValuesError(false);
        userAccepted = true;
        blankValuesHandled = true;
      }
    }
    PropertyStore.setBlankContendedValuesAccepted(
      propertyId,
      hasBlankValue,
      userAccepted,
      checkAlreadyOnDisplay
    );
    return blankValuesHandled;
  }

  function handleTisChange(target) {
    setProperty({ ...property, [target.name]: target.value });
    updateTisHelper(target.value, true);
  }

  // tisRequired will tell, if these fields
  function updateTisHelper(value, tisRequired) {
    let updateHelper = {};
    updateHelper = Object.assign(updateHelper, objectionDetailsHelper);
    if (value && tisRequired) {
      if (value === TIS_FILLED_YES) {
        updateHelper[Field_Year_TIS_Submitted].required = true;
        updateHelper[Field_Year_TIS_Submitted].helperText = constants.REQUIRED;
        refreshHelper(updateHelper, Field_TIS_Attachments);
      } else if (value === TIS_FILLED_NO) {
        updateHelper[Field_TIS_Attachments].required = true;
        updateHelper[Field_TIS_Attachments].helperText = constants.REQUIRED;
        refreshHelper(updateHelper, Field_Year_TIS_Submitted);
      }
    } else {
      refreshHelper(updateHelper, Field_TIS_Attachments);
      refreshHelper(updateHelper, Field_Year_TIS_Submitted);
    }
    setObjectionDetailsHelper(updateHelper);
  }

  function refreshHelper(helper, field) {
    helper[field].required = false;
    helper[field].helperText = "";
    helper[field].hasError = false;
    helper[field].errorText = "";
  }
  //#endregion helper functions

  //#region event handler
  function goToPropertyIdentification() {
    props.history &&
      props.history.push({
        pathname: "/PropertyIdentification",
        objectorId: objector.objectorId,
      });
  }

  function checkboxChanged() {
    let accepted = blankContendedValues.accepted;
    let fieldName = "accepted";
    setBlankContendedValues({
      ...blankContendedValues,
      [fieldName]: !accepted,
    });
    PropertyStore.setBlankContendedValuesAccepted(
      propertyId,
      blankContendedValues.hasBlankValues,
      !accepted,
      blankContendedValues.displayedAcceptenceCheck
    );
  }

  function radioChanged({ target }) {
    // if user has changed objection Reason, apply the following rules
    if (target.name === Field_Objection_Reason) {
      handleObjectionReasonChange(target);
    } else if (target.name === Field_TIS_PREVIOUSLY_FILLED) {
      handleTisChange(target);
    } else {
      setProperty({ ...property, [target.name]: target.value });
    }
  }

  function handleObjectionReasonChange(target) {
    let updateProperty = {};
    updateProperty = Object.assign(updateProperty, property);
    // if user didnt select objection reason = Value_To_High, clean the control and hide fields
    if (target.value === Objection_Reason_Land_Not_Correct) {
      // cleaning up the field before hiding it
      updateProperty.contendedNetAnnualValue = "";
      // we also need to update the helper, to make TIS fields non-mandatory, bcoz Objection Reason !== VALUE_TO_HIGH
      updateTisHelper(property.tisPreviouslyFilled, false);

      // update the breadcrumbs/stepper to show Rental Evidence
      BreadcrumbActions.changeRouteVisibility(Rental_Evidence, false);
      // initializing the Contended property code
      let propertyCategory = propertyCategories.find((x) => x.id === "0");
      updateProperty.contended_property_code = propertyCategory.id;
      updateProperty.contended_property_desc = propertyCategory.name;
    } else {
      updateTisHelper(property.tisPreviouslyFilled, true);
      // for all other Objection Reasons, we want to hide Rental Evidence Route
      BreadcrumbActions.changeRouteVisibility(Rental_Evidence, true);
    }

    // finally updating the value
    updateProperty.objectionReasonId = target.value;
    setProperty(updateProperty);
  }

  function handleChange({ target }) {
    if (target.name === "contendedNetAnnualValue") {
      let updatedBlankValues = {
        propertyId: propertyId,
        hasBlankValues: false,
        displayedAcceptenceCheck: false,
        accepted: false,
      };
      if (target.value && target.value !== "") {
        setBlankValuesError(false);
      } else {
        updatedBlankValues.hasBlankValues = true;
      }
      setBlankContendedValues(updatedBlankValues);
      PropertyStore.setBlankContendedValuesAccepted(updatedBlankValues);
    } else if (target.name === "propertyTypeId") {
      handlePropertyTypeChange(target.value);
    }
    setProperty({ ...property, [target.name]: target.value });
  }

  function handlePropertyTypeChange(value) {
    let updateProperty = {};
    if (value === PropertyType_Residential) {
      // clear all controls which are not required
      updateProperty = Object.assign(updateProperty, property);
      updateProperty.propertyTypeId = value;
      updateProperty.netLettableArea = "";
      updateProperty.carParks = "";
      updateProperty.leaseTerm = "";
      updateProperty.leaseAmountPA = "";
      updateProperty.leaseCommenced = null;
      updateProperty.reviewFrequency = "";
      updateProperty.reviewBasis = "";
      updateProperty.options = "";
      updateProperty.reviewDetail = "";
      updateProperty.tenantOutgoings = "";
      updateProperty.ownerOutgoings = "";
      setProperty(updateProperty);
    } else if (value === PropertyType_Commercial) {
      updateProperty = Object.assign(updateProperty, property);
      updateProperty.numberBathrooms = "";
      updateProperty.numberBedrooms = "";
      updateProperty.numberRooms = "";
      updateProperty.propertyTypeId = value;
      setProperty(updateProperty);
    }
  }

  function onAutoCompleteChange(event, value) {
    if (value) {
      let updateProperty = {};
      updateProperty = Object.assign(updateProperty, property);
      updateProperty.contended_property_code = value.id;
      updateProperty.contended_property_desc = value.name;
      setProperty(updateProperty);
    }
  }

  function handleAttachmentUploaded(attachment) {
    if (attachment.attachmentType === constants.TIS_ATTACHMENTS) {
      setProperty({
        ...property,
        [Field_TIS_Attachments]: attachment.fileName,
      });
    }
  }

  function onBlur(event) {
    let fieldDetails = ObjectionDetailsHelper.getFieldDetail(event.target.name);
    let finalValue = event.target.value;
    if (finalValue && finalValue !== "") {
      if (fieldDetails && fieldDetails.type === "currency") {
        finalValue = FormatCurrency.removeCurrencyFormat(event.target.value);
        if (fieldDetails.isPositiveOnly) {
          finalValue = finalValue < 0 ? finalValue * -1 : finalValue;
        }
        finalValue = FormatCurrency.formatCurrency(
          FormatCurrency.removeCurrencyFormat(finalValue)
        );
      }
    }
    // field level required validation
    fieldLevelValidation(
      objectionDetailsHelper[event.target.name],
      event.target.value
    );
    ObjectionActions.updateObjectionDetailsHelper(objectionDetailsHelper);
    setProperty({ ...property, [event.target.name]: finalValue });
  }

  function attachmentDeleted(attachmentType) {
    if (attachmentType === constants.TIS_ATTACHMENTS) {
      setProperty({ ...property, [Field_TIS_Attachments]: "" });
    }
  }

  function onDateChange(date, name) {
    if (!isNaN(date) && date !== null && date <= new Date()) {
      setProperty({ ...property, [name]: date });
    }
  }
  //#endregion event handler

  //#region JSX Code
  return (
    <>
      <MDBContainer>
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <h4>{property.address}</h4>
            <h5>{getNamefromList(Councils, property.councilId)}</h5>
            <h5>Assessment Number: {property.assessmentNumber}</h5>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        <MDBRow className="page-sub-headings mb-3">
          <MDBCol>Grounds for objecting *</MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="objection-reason mb-3"
          >
            <MaterialRadio
              list={objectionReasons}
              name="objectionReasonId"
              value={property.objectionReasonId}
              aria-required={true}
              radioChanged={radioChanged}
              required={objectionDetailsHelper.objectionReasonId.required}
              error={objectionDetailsHelper.objectionReasonId.hasError}
              helperText={objectionDetailsHelper.objectionReasonId.errorText}
              onBlur={onBlur}
            ></MaterialRadio>
          </MDBCol>
        </MDBRow>

        {property.objectionReasonId === Objection_Reason_Land_Not_Correct && (
          <div>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialSelect
                  inputLabel="Current property code"
                  name="property_code"
                  value={property.property_code}
                  list={propertyCategories}
                  disabled={true}
                  required={false}
                  error={false}
                />
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <div className="contended-property-layout">
                  <MaterialAutoComplete
                    name="contended_property_desc"
                    defaultValue={propertyCategories.findIndex(
                      (x) => x.id === property.contended_property_code
                    )}
                    id={property.contended_property_desc}
                    label="Contended property code"
                    onChange={onAutoCompleteChange}
                    list={propertyCategories}
                    disabled={false}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        )}

        {property.objectionReasonId !== Objection_Reason_Land_Not_Correct && (
          <>
            <MDBRow className="objection-details-background mb-3">
              <MDBCol>
                <div
                  className="cms-content"
                  dangerouslySetInnerHTML={{ __html: objectingToValuesText }}
                ></div>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
                <b>
                  <b>Rate notice</b>
                </b>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
                <MaterialInputCurrency
                  name="netAnnualValue"
                  label="Annual Value ($)"
                  required={false}
                  helperText="Assessed Annual Value (AAV)"
                  value={property.netAnnualValue}
                  disabled={true}
                  hasError={false}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
                <b>
                  <b>Contended values</b>
                </b>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
                <MaterialInputCurrency
                  id="obj-details-cnav"
                  name="contendedNetAnnualValue"
                  label="Contended Annual Value ($)"
                  helperText="Contended Assessed Annual Value (AAV)"
                  value={property.contendedNetAnnualValue}
                  onChange={handleChange}
                  disabled={false}
                  required={
                    objectionDetailsHelper.contendedNetAnnualValue.required
                  }
                  hasError={
                    objectionDetailsHelper.contendedNetAnnualValue.hasError
                  }
                  onBlur={onBlur}
                />
              </MDBCol>
            </MDBRow>

            {!property.contendedNetAnnualValue &&
              blankContendedValues.displayedAcceptenceCheck && (
                <div id="blankValueWarningSection">
                  <MDBRow className="objecting-values-warning mb-3">
                    <MDBCol>
                      <MDBRow>
                        <MDBCol>
                          <div
                            className="cms-content"
                            dangerouslySetInnerHTML={{
                              __html: objectingToValuesWarning,
                            }}
                          ></div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                          <div className="custom-control custom-checkbox">
                            <input
                              aria-labelledby="accept-blank-values"
                              type="checkbox"
                              className="custom-control-input"
                              id="defaultUnchecked"
                              onChange={checkboxChanged}
                              checked={blankContendedValues.accepted}
                            />

                            <label
                              id="label-accept-blank-values"
                              className={`custom-control-label ${
                                blankValuesError ? "checkboxError" : ""
                              }`}
                              htmlFor="defaultUnchecked"
                            >
                              I have intentionally left the contended values
                              blank.
                            </label>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </div>
              )}
          </>
        )}
        <MDBRow className="objection-details-background mb-3">
          <MDBCol>
            <div
              className="cms-content"
              dangerouslySetInnerHTML={{ __html: objectionCommentsText }}
            ></div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-3">
          <MDBCol>
            <MDBTextArea
              label="Objection Comments"
              name="objectionComment"
              value={property.objectionComment}
              onChange={handleChange}
              disabled={false}
              required={objectionDetailsHelper.objectionComment.required}
              hasError={objectionDetailsHelper.objectionComment.hasError}
            ></MDBTextArea>
          </MDBCol>
        </MDBRow>

        {property.objectionReasonId !== Objection_Reason_Land_Not_Correct && (
          <>
            <PropertyDetails
              property={property}
              objector={objector}
              handleChange={handleChange}
              radioChanged={radioChanged}
              onBlur={onBlur}
              objectionDetailsHelper={objectionDetailsHelper}
              onAutoCompleteChange={onAutoCompleteChange}
              handleAttachmentUploaded={handleAttachmentUploaded}
              attachmentDeleted={attachmentDeleted}
              onDateChange={onDateChange}
            ></PropertyDetails>
          </>
        )}

        <Attachments
          propertyId={property.propertyId}
          objectorId={objector.objectorId}
          label="Attach documents or photos which you believe may assist in the
              review of your valuation."
          heading="Supporting Evidence"
          errorText=""
          attachmentType={Constants.PROPERTY_ATTACHMENTS}
          handleAttachmentUploaded={handleAttachmentUploaded}
          disabled={false}
          attachmentDeleted={attachmentDeleted}
          buttonText="Add Attachment"
          filesLimit={Max_Files_Property}
          tisAttachments={property.tisAttachments}
        />

        <MDBRow className="mt-3">
          <MDBCol>
            <div className="float-left" id="backButton">
              <MDBBtn
                aria-labelledby="backButton"
                className="button-style"
                onClick={goToPropertyIdentification}
              >
                Back
              </MDBBtn>
            </div>

            <div className="float-right" id="nextButton">
              <MDBBtn
                aria-labelledby="nextButton"
                className="button-style"
                onClick={saveObjectionDetails}
              >
                Next
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
  //#endregion JSX Code
}

export default ObjectionDetails;
