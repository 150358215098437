import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { getNamefromList } from "../../../utilities/getNameFromList";
import { valueCheck } from "../../../utilities/valueCheck";
import { dateFormat } from "../../../utilities/dateFormat";
import Comparability from "../../../Lists/Comparability";
import leaseTerms from "../../../Lists/LeaseTerms";
import { getLabelFromSliderList } from "../../../utilities/getLabelFromSliderList";

function ReviewSalesEvidence(props) {
  const salesEvidences = props.salesEvidences;
  const landAreaUnitID = props.landAreaUnitID;
  const Not_Entered_Amount = "0";
  const Not_Selected_Value = "-1";

  return (
    <>
      {salesEvidences &&
        salesEvidences.map((value, index) => {
          const showAddress = valueCheck(
            value.address,
            true,
            true,
            false,
            null
          );

          const showSaleListingURL = valueCheck(
            value.saleListingURL,
            true,
            true,
            false,
            null
          );

          const showSaleDate = valueCheck(
            value.saleDate,
            true,
            true,
            false,
            null
          );

          const showSalePrice = valueCheck(
            value.salePrice,
            true,
            true,
            true,
            Not_Entered_Amount
          );

          const showLandAreaM2 = valueCheck(
            value.landAreaM2,
            true,
            true,
            false,
            null
          );

          const showBuildAreaM2 = valueCheck(
            value.buildAreaM2,
            true,
            true,
            false,
            null
          );

          const showLeaseTermId = valueCheck(
            value.leaseTermId,
            true,
            true,
            true,
            Not_Selected_Value
          );

          const showLeaseAmountPa = valueCheck(
            value.leaseAmountPa,
            true,
            true,
            true,
            Not_Entered_Amount
          );

          const showLeaseOptions = valueCheck(
            value.leaseOptions,
            true,
            true,
            false,
            null
          );

          const showBuildingCondition = valueCheck(
            value.buildingConditionId,
            true,
            true,
            true,
            Not_Selected_Value
          );

          const showSaleDescription = valueCheck(
            value.saleDescription,
            true,
            true,
            false,
            null
          );

          const showSalesEvidenceComponent =
            showAddress ||
            showSaleListingURL ||
            showSaleDate ||
            showSalePrice ||
            showLandAreaM2 ||
            showBuildAreaM2 ||
            showLeaseTermId ||
            showLeaseAmountPa ||
            showLeaseOptions ||
            showBuildingCondition ||
            showSaleDescription;

          return (
            <>
              {showSalesEvidenceComponent && (
                <div>
                  <MDBRow className="mt-3 mb-3">
                    <MDBCol className="review-section-headings">
                      Rental Evidence {index + 1}
                    </MDBCol>
                  </MDBRow>

                  {(showAddress || showSaleListingURL) && (
                    <>
                      <MDBRow>
                        {showAddress && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Address
                              </span>
                              <span className="review-page-field-value">
                                {value.address}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                        {showSaleListingURL && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Sale url
                              </span>
                              <span className="review-page-field-value">
                                {value.saleListingURL}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                      </MDBRow>
                    </>
                  )}

                  {(showSaleDate || showSalePrice) && (
                    <>
                      <MDBRow>
                        {showSaleDate && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Sale date
                              </span>
                              <span className="review-page-field-value">
                                {dateFormat(value.saleDate, "dd/mm/yyyy")}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                        {showSalePrice && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Sale price
                              </span>
                              <span className="review-page-field-value">
                                {"$" + value.salePrice}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                      </MDBRow>
                    </>
                  )}

                  {(showLandAreaM2 || showBuildAreaM2) && (
                    <>
                      <MDBRow>
                        {showLandAreaM2 && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Land area
                              </span>
                              <span className="review-page-field-value">
                                {value.landAreaM2}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                        {showBuildAreaM2 && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                GFA
                              </span>
                              <span className="review-page-field-value">
                                {value.buildAreaM2}
                                {landAreaUnitID === "1" ? " M" : null}
                                {landAreaUnitID === "1" ? <sup>2</sup> : null}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                      </MDBRow>
                    </>
                  )}

                  {(showLeaseTermId || showLeaseAmountPa) && (
                    <>
                      <MDBRow>
                        {showLeaseTermId && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Lease term
                              </span>
                              <span className="review-page-field-value">
                                {getNamefromList(
                                  leaseTerms,
                                  value.leaseTermId.toString()
                                )}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                        {showLeaseAmountPa && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Lease amount
                              </span>
                              <span className="review-page-field-value">
                                {"$" + value.leaseAmountPa}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                      </MDBRow>
                    </>
                  )}

                  {showLeaseOptions && (
                    <>
                      <MDBRow className="mb-3">
                        <MDBCol>
                          <div className="review-page-field">
                            <span className="review-page-field-heading">
                              Lease renewal options
                            </span>
                            <span className="review-page-field-value">
                              {value.leaseOptions}
                            </span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}

                  {/*  */}
                  {(showBuildingCondition || showSaleDescription) && (
                    <>
                      <MDBRow>
                        {showBuildingCondition && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Comparability
                              </span>
                              <span className="review-page-field-value">
                                {getLabelFromSliderList(
                                  Comparability,
                                  value.buildingConditionId.toString()
                                )}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                        {showSaleDescription && (
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mb-3"
                          >
                            <div className="review-page-field">
                              <span className="review-page-field-heading">
                                Comparability comment
                              </span>
                              <span className="review-page-field-value">
                                {value.saleDescription}
                              </span>
                            </div>
                          </MDBCol>
                        )}
                      </MDBRow>
                    </>
                  )}
                </div>
              )}
            </>
          );
        })}
    </>
  );
}

export default ReviewSalesEvidence;
