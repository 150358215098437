//#region imports
import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import reviewBasis from "../../../Lists/ReviewBasis";
import reviewFrequencies from "../../../Lists/ReviewFrequencies";
import MDBTextArea from "../../../controls/MDBReact-InputTextArea";
import MaterialInputText from "../../../controls/MaterialUI-InputText";
import leaseTerms from "../../../Lists/LeaseTerms";
import MaterialSelect from "../../../controls/MaterialUI-Select";
import MaterialInputDate from "../../../controls/MaterialUI-InputDate";
import MaterialInputCurrency from "../../../controls/MaterialUI-InputCurrency";
//#endregion imports

function PropertyLeaseDetails(props) {
  const [property, setProperty] = useState(props.property);
  const [objectionDetailsHelper, setObjectionDetailsHelper] = useState(
    props.objectionDetailsHelper
  );

  //#region UseEffect Hook
  useEffect(() => {
    setProperty(props.property);
    setObjectionDetailsHelper(props.objectionDetailsHelper);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.property]);
  //#endregion UseEffect Hook

  return (
    <>
      <MDBRow className="page-sub-headings mb-3">
        <MDBCol>Lease Details</MDBCol>
      </MDBRow>
      <MDBRow className="static-text-contents mb-3">
        <MDBCol>
          If there is an active lease on the property, please detail below:
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Lease term"
            name="leaseTerm"
            value={property.leaseTerm}
            onSelectChange={props.handleChange}
            list={leaseTerms}
            formHelperText={objectionDetailsHelper.leaseTerm.errorText}
            disabled={false}
            required={objectionDetailsHelper.leaseTerm.required}
            error={objectionDetailsHelper.leaseTerm.hasError}
            onBlur={props.onBlur}
          ></MaterialSelect>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputCurrency
            id="leaseDetailsLeaseAmount"
            label="Lease amount per year ($)"
            name="leaseAmountPA"
            value={property.leaseAmountPA}
            onChange={props.handleChange}
            disabled={false}
            helperText={objectionDetailsHelper.leaseAmountPA.errorText}
            required={objectionDetailsHelper.leaseAmountPA.required}
            hasError={objectionDetailsHelper.leaseAmountPA.hasError}
            onBlur={props.onBlur}
          ></MaterialInputCurrency>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="6"
          className="mb-3  date-two-column"
        >
          <MaterialInputDate
            id="leaseCommencedId"
            name="leaseCommenced"
            label="Lease commenced"
            aria-required={true}
            value={property.leaseCommenced}
            onChange={(date) => {
              props.onDateChange(date, "leaseCommenced");
            }}
            helperText={objectionDetailsHelper.leaseCommenced.errorText}
            required={objectionDetailsHelper.leaseCommenced.required}
            hasError={objectionDetailsHelper.leaseCommenced.hasError}
          />
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Review frequency"
            name="reviewFrequency"
            value={property.reviewFrequency}
            onSelectChange={props.handleChange}
            list={reviewFrequencies}
            formHelperText={objectionDetailsHelper.reviewFrequency.errorText}
            disabled={false}
            required={objectionDetailsHelper.reviewFrequency.required}
            error={objectionDetailsHelper.reviewFrequency.hasError}
            onBlur={props.onBlur}
          ></MaterialSelect>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialSelect
            inputLabel="Review basis"
            name="reviewBasis"
            value={property.reviewBasis}
            onSelectChange={props.handleChange}
            list={reviewBasis}
            formHelperText={objectionDetailsHelper.reviewBasis.errorText}
            disabled={false}
            required={objectionDetailsHelper.reviewBasis.required}
            error={objectionDetailsHelper.reviewBasis.hasError}
            onBlur={props.onBlur}
          ></MaterialSelect>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Lease renewal options"
            name="options"
            value={property.options}
            onChange={props.handleChange}
            disabled={false}
            required={objectionDetailsHelper.options.required}
            hasError={objectionDetailsHelper.options.hasError}
            helperText={objectionDetailsHelper.options.errorText}
            onBlur={props.onBlur}
          ></MaterialInputText>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol className="static-text-contents">
          <div>Please provide any extra information regarding the lease:</div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol>
          <MDBTextArea
            label="Lease details"
            name="reviewDetail"
            value={property.reviewDetail}
            onChange={props.handleChange}
            disabled={false}
            required={objectionDetailsHelper.reviewDetail.required}
            hasError={objectionDetailsHelper.reviewDetail.hasError}
            helperText={objectionDetailsHelper.reviewDetail.errorText}
          ></MDBTextArea>
        </MDBCol>
      </MDBRow>
      <MDBRow className="page-sub-headings mb-3">
        <MDBCol>Outgoings</MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          Tenant
          <MaterialInputCurrency
            label="Amount paid per year ($)"
            name="tenantOutgoings"
            value={property.tenantOutgoings}
            onChange={props.handleChange}
            disabled={false}
            helperText={objectionDetailsHelper.tenantOutgoings.errorText}
            required={objectionDetailsHelper.tenantOutgoings.required}
            hasError={objectionDetailsHelper.tenantOutgoings.hasError}
            onBlur={props.onBlur}
          ></MaterialInputCurrency>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          Owner
          <MaterialInputCurrency
            label="Amount paid per year ($)"
            name="ownerOutgoings"
            value={property.ownerOutgoings}
            onChange={props.handleChange}
            disabled={false}
            helperText={objectionDetailsHelper.ownerOutgoings.errorText}
            required={objectionDetailsHelper.ownerOutgoings.required}
            hasError={objectionDetailsHelper.ownerOutgoings.hasError}
            onBlur={props.onBlur}
          ></MaterialInputCurrency>
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default PropertyLeaseDetails;
