import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import MaterialSelect from "../../controls/MaterialUI-Select";
import Councils from "../../Lists/Councils";
import PropertyStore from "../../stores/propertyStore";
import ObjectorStore from "../../stores/objectorStore";
import * as ObjectorActions from "../../actions/objectorActions";
import {
  fieldValidator,
  fieldLevelValidation,
} from "../../utilities/fieldValidator";
import constants from "../Constants";
import * as PropertyActions from "../../actions/propertyActions";
import * as AttachmentActions from "../../actions/attachmentsActions";
import * as SalesEvidenceActions from "../../actions/salesEvidenceActions";
import { isEmailLink } from "../../utilities/isEmailLink";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import * as CommonActions from "../../actions/commonActions";
import * as Session from "../Session/Session";

function ValidateObjector(props) {
  // check if the component has been loaded using the email link
  var objectorId = isEmailLink(props);

  if (!objectorId) {
    if (props && props.location && props.location.objectorId) {
      objectorId = props.location.objectorId;
    } else {
      props.history &&
        props.history.push({
          pathname: "/",
        });
    }
  }

  const Adelaide_Rate_Notice_Id = "808";
  const [propertyLoginError, setPropertyLoginError] = useState(
    PropertyStore.getPropertyLoginError()
  );
  const [newProperty, setNewProperty] = useState(
    PropertyStore.getNewProperty()
  );

  const [validateObjectorHelper, setValidateObjectorHelper] = useState(
    ObjectorStore.getValidateObjectorHelper()
  );

  //#region useEffect State Hook
  useEffect(() => {
    ObjectorStore.addChangeListener(onchange);

    document.title = "Object to Rating Valuation - Returning to an Objection";

    Session.clearAll();
    // we also need to clear the flux store
    AttachmentActions.initialiseAttachment();
    PropertyActions.initialiseProperty();
    SalesEvidenceActions.initialiseSalesEvidence();

    // fetching all the cms contents
    AttachmentActions.initialiseAttachment();
    PropertyActions.initialiseProperty();
    SalesEvidenceActions.initialiseSalesEvidence();
    CommonActions.getCouncilRatesNotice(Adelaide_Rate_Notice_Id);
    CommonActions.getPropertyVerificationModalText();
    CommonActions.getObjectingToValuesText();
    CommonActions.getObjectionCommentsText();
    CommonActions.getPropertyDetailsText();
    CommonActions.getSalesEvidenceText();
    CommonActions.getObjectingToValuesWarning();
    CommonActions.getPropertyVerificationMainText();

    function onchange() {
      setValidateObjectorHelper(ObjectorStore.getValidateObjectorHelper());
    }

    return () => ObjectorStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, []);
  //#endregion useEffect State Hook

  function onBlur(event) {
    // field level required validation
    fieldLevelValidation(
      validateObjectorHelper[event.target.name],
      event.target.value
    );
    ObjectorActions.updateValidateObjectorHelper(validateObjectorHelper);
  }

  function onSubmitClick() {
    startLoader();
    let isFieldValidationSuccess = fieldValidator(
      constants.VALIDATE_OBJECTOR,
      newProperty,
      validateObjectorHelper
    );

    ObjectorActions.updateValidateObjectorHelper(validateObjectorHelper);

    if (!isFieldValidationSuccess) {
      setTimeout(() => {
        stopLoader();
      }, 100);
      return;
    }

    ObjectorActions.validateObjector(
      objectorId,
      newProperty.councilId,
      newProperty.assessmentNumber
    ).then((response) => {
      if (response && response.errors && response.errors.length > 0) {
        setPropertyLoginError(
          "The council or assessment number you have provided do not match properties associated with your previous session."
        );
      } else {
        setPropertyLoginError("");

        // this getObjectorById will make Api calls for objector, properties, salesevidence and attachments
        ObjectorActions.getObjectorById(objectorId).then((response) => {
          // we also need to fetch the properties for this
          setTimeout(() => {
            stopLoader();
            props.history &&
              props.history.push({
                pathname: "/PropertyIdentification",
                objectorId: objectorId,
              });
          }, 1500);
        });
      }
    });
  }

  function onSelectChange(event) {
    let target = event.target;
    setNewProperty({ ...newProperty, [target.name]: target.value });
  }

  function onHandleChange(event) {
    let target = event.target;
    setNewProperty({ ...newProperty, [target.name]: target.value });
  }

  return (
    <>
      <MDBContainer>
        <MDBRow className="page-sub-headings  mt-3 mb-3">
          <MDBCol>Returning to an Objection</MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            To log back in, please provide property identification details from
            any objection in your previous session.
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialSelect
              inputLabel="Council"
              name="councilId"
              value={newProperty.councilId}
              onSelectChange={onSelectChange}
              list={Councils}
              formHelperText={validateObjectorHelper.councilId.helperText}
              disabled={false}
              required={validateObjectorHelper.councilId.required}
              error={validateObjectorHelper.councilId.hasError}
              onBlur={(event) => {
                onBlur(event);
              }}
            />
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialInputText
              name="assessmentNumber"
              label="Assessment Number"
              defaultValue=""
              helperText={validateObjectorHelper.assessmentNumber.helperText}
              required={validateObjectorHelper.assessmentNumber.required}
              value={newProperty.assessmentNumber}
              onChange={onHandleChange}
              disabled={false}
              hasError={validateObjectorHelper.assessmentNumber.hasError}
              onBlur={(event) => {
                onBlur(event);
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {propertyLoginError && (
              <div className="errorMessages">{propertyLoginError}</div>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCol>
            <MDBBtn
              className="float-right"
              type="submit"
              onClick={onSubmitClick}
            >
              Login
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default ValidateObjector;
