//#region imports
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import ObjectorStore from "../../stores/objectorStore";
import MaterialCheckbox from "../../controls/MaterialUI-Checkbox";
import * as ObjectorActions from "../../actions/objectorActions";
import * as PropertyActions from "../../actions/propertyActions";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import respondentTypes from "../../Lists/RespondentTypes";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import {
  fieldValidator,
  fieldLevelValidation,
} from "../../utilities/fieldValidator";
import constants from "../Constants";
import MaterialSelect from "../../controls/MaterialUI-Select";
import professions from "../../Lists/Professions";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import Steppers from "../../controls/Steppers/Steppers";
import { isEmailLink } from "../../utilities/isEmailLink";
import "./ObjectorDetails.scss";
import { ScrollTo } from "../../utilities/focusErrorControl";
import Alert from  "@material-ui/lab/Alert";
//#endregion imports

function ObjectorDetails(props) {
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }

  //#region component state variable declarations
  const RESPONDENT_TYPE_AGENT = "Agent";
  const RESPONDANT_TYPE = "respondantType";
  const [objector, setObjector] = useState(ObjectorStore.getObjector());
  const [objectorHelper, setObjectorHelper] = useState(
    ObjectorStore.getObjectorHelper()
  );
  const [errorMessage, setErrorMessage] = useState(null);
  //#endregion component state variable declarations

  //#region useEffect State Hook
  useEffect(() => {
    ObjectorStore.addChangeListener(onchange);

    document.title = "Object to Rating Valuation - Objector Details";

    function onchange() {
      setObjector(ObjectorStore.getObjector());
      setObjectorHelper(ObjectorStore.getObjectorHelper());
      // changing the booleans converted to string, back to booleans
      let field = "objectionAuthorisation";
      let value =
        objector.objectionAuthorisation === "true" ||
        objector.objectionAuthorisation === true
          ? "true"
          : "false";
      setObjector({ ...objector, [field]: value });
    }
    return () => ObjectorStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, [objector, objectorHelper]);
  //#endregion useEffect State Hook

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  //#endregion useEffect State Hook

  //#region control change events
  function handleChange({ target }) {
    setObjector({ ...objector, [target.name]: target.value });
  }

  function respondantTypeChange({ target }) {
    let agentFieldRequired = false;
    if (
      target.name === RESPONDANT_TYPE &&
      target.value !== RESPONDENT_TYPE_AGENT
    ) {
      let objectorUpdate = {};
      objectorUpdate = Object.assign(objectorUpdate, objector);
      objectorUpdate.respondantType = target.value;
      objectorUpdate.professionId = "";
      objectorUpdate.companyName = "";
      setObjector({ ...objectorUpdate, [target.name]: target.value });
    } else {
      setObjector({ ...objector, [target.name]: target.value });
      agentFieldRequired = true;
    }
    // we need to dynamically update the nature of the two fields
    // 1) companyName 2) professionId. If user selects respondantType = Agent
    // both of these fields become required. otherwise they are not-required
    let updateObjectorHelper = {};
    updateObjectorHelper = Object.assign(updateObjectorHelper, objectorHelper);
    updateObjectorHelper["companyName"].required = agentFieldRequired;
    updateObjectorHelper["professionId"].required = agentFieldRequired;
    setObjectorHelper(updateObjectorHelper);
  }

  function onBlur({ target }) {
    fieldLevelValidation(objectorHelper[target.name], target.value);
    ObjectorActions.dispatchUpdateObjectorHelper(objectorHelper);
  }

  function checkboxChanged(event) {
    let value = event.target.checked ? "true" : "false";
    setObjector({
      ...objector,
      [event.target.name]: value,
    });
  }
  //#endregion control change events

  //#region save data
  function saveObjector() {
    startLoader();
    let isFieldValidationSuccess = fieldValidator(
      constants.OBJECTOR,
      objector,
      objectorHelper
    );

    if (!isFieldValidationSuccess) {
      setTimeout(() => {
        stopLoader();
        ObjectorActions.dispatchUpdateObjectorHelper(objectorHelper);
      }, 100);
      return;
    }

    ObjectorActions.saveObjector(objector).then((response) => {
      const GENERAL_ERROR = 'Objector details failed to save. Refresh the page and try again. If the problem persists, contact the council on your notice and lodge your objection directly with the rates team.';

      // if api returns an error thats handled by the server, it would be 
      // wrapped in the response
      if (response && response.errors && response.errors.length > 0) {
        showError(GENERAL_ERROR);
        stopLoader();
        return;
      }

      if (response) {
        // if it was a POST then response will have objectorId
        objector.objectorId = response.objectorId;
      } else {
        // if it was a PUT then we already have objectorId, so we check
        // if there is any data in the database
        ObjectorActions.getObjectorById(objector.objectorId);
      }

      ObjectorActions.dispatchSaveObjector(objector);

      // we also need to fetch the properties for this
      PropertyActions.getPropertiesByObjectorId(objector.objectorId).then(
        (response) => {
          if (response && response.errors && response.errors.length > 0) {
            showError(GENERAL_ERROR);
            stopLoader();
            return;
          } else {
            setTimeout(() => {
              stopLoader();
              props.history &&
                props.history.push({
                  pathname: "/PropertyIdentification",
                  objectorId: objector.objectorId,
                });
              ObjectorActions.dispatchUpdateObjectorHelper(objectorHelper);
            }, 1500);
          }
        }
      );
    });
  }

  function backToHomePage() {
    props.history &&
      props.history.push({
        pathname: "/",
      });
  }

  function showError(message) {
    setErrorMessage(message);
    let element = document.getElementById("objectorError");
    if (element) {
      ScrollTo(element);
    }
  }

  //#region JSX code
  return (
    <>
      <MDBContainer>
        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-3">
          <MDBCol>
            Please provide the relevant contact information to assist in the
            processing of your objection. If you are objecting on behalf of
            someone else, you will need to provide authorisation during the form
            submission.
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="12" lg="12" md="12" sm="12" className="mb-3">
            <div id="objectorError"> 
            { errorMessage && 
              <Alert severity="error">
                { errorMessage }
              </Alert>
            }
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="page-sub-headings mb-3">
          <MDBCol>Personal Details</MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="4" lg="4" md="4" sm="4" xs="4" className="mb-3">
            <MaterialInputText
              id="firstName"
              label="First name"
              aria-required={true}
              name="firstName"
              onChange={handleChange}
              value={objector.firstName}
              helperText={
                objectorHelper.firstName.errorText !== "" &&
                objectorHelper.firstName.errorText !== null
                  ? objectorHelper.firstName.errorText
                  : objectorHelper.firstName.helperText
              }
              required={objectorHelper.firstName.required}
              hasError={objectorHelper.firstName.hasError}
              onBlur={onBlur}
            />
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="4" xs="4" className="mb-3">
            <MaterialInputText
              id="lastName"
              label="Last name"
              aria-required={true}
              name="lastName"
              onChange={handleChange}
              value={objector.lastName}
              helperText={
                objectorHelper.lastName.errorText !== "" &&
                objectorHelper.lastName.errorText !== null
                  ? objectorHelper.lastName.errorText
                  : objectorHelper.lastName.helperText
              }
              required={objectorHelper.lastName.required}
              hasError={objectorHelper.lastName.hasError}
              onBlur={onBlur}
            />
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="4" xs="4" className="mb-3">
            <MaterialSelect
              inputLabel="Respondent type"
              name="respondantType"
              aria-required={true}
              value={objector.respondantType}
              onSelectChange={respondantTypeChange}
              list={respondentTypes}
              formHelperText={
                objectorHelper.respondantType.errorText !== "" &&
                objectorHelper.respondantType.errorText !== null
                  ? objectorHelper.respondantType.errorText
                  : objectorHelper.respondantType.helperText
              }
              disabled={false}
              required={objectorHelper.respondantType.required}
              error={objectorHelper.respondantType.hasError}
              onBlur={onBlur}
            />
          </MDBCol>
        </MDBRow>

        {objector.respondantType === RESPONDENT_TYPE_AGENT ? (
          <>
            <MDBRow>
              <MDBCol xl="4" lg="4" md="4" sm="4" xs="4" className="mb-3">
                <MaterialSelect
                  inputLabel="Profession"
                  name="professionId"
                  value={objector.professionId}
                  onSelectChange={handleChange}
                  list={professions}
                  disabled={false}
                  required={objectorHelper.professionId.required}
                  error={objectorHelper.professionId.hasError}
                  formHelperText={
                    objectorHelper.professionId.errorText !== "" &&
                    objectorHelper.professionId.errorText !== null
                      ? objectorHelper.professionId.errorText
                      : objectorHelper.professionId.helperText
                  }
                  onBlur={onBlur}
                />
              </MDBCol>
              <MDBCol xl="8" lg="8" md="8" sm="8" xs="8" className="mb-3">
                <MaterialInputText
                  id="companyName"
                  label="Company name"
                  name="companyName"
                  onChange={handleChange}
                  value={objector.companyName}
                  helperText={
                    objectorHelper.companyName.errorText !== "" &&
                    objectorHelper.companyName.errorText !== null
                      ? objectorHelper.companyName.errorText
                      : objectorHelper.companyName.helperText
                  }
                  required={objectorHelper.companyName.required}
                  hasError={objectorHelper.companyName.hasError}
                  onBlur={onBlur}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="page-sub-headings mb-3">
              <MDBCol className="mb-3">
                <div
                  aria-label="Objection Authorisation"
                  id="labelobjectionAuthorisation"
                >
                  Objection Authorisation
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol className="mb-3">
                <div>
                  <MaterialCheckbox
                    id="objectionAuthorisation"
                    onChange={checkboxChanged}
                    name="objectionAuthorisation"
                    checked={
                      objector.objectionAuthorisation === "true" ? true : false
                    }
                    label="Notice is hereby given that the owners appoint the above named agent to
                    act on their behalf regarding the objection(s) contained within this
                    submission."
                  ></MaterialCheckbox>
                </div>
              </MDBCol>
            </MDBRow>
          </>
        ) : null}

        <MDBRow className="page-sub-headings mb-3">
          <MDBCol>
            <div id="objector-page-label">Communication Details</div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol className="mb-3">
            <MaterialInputText
              id="postalAddress"
              label="Postal address"
              name="postalAddress"
              aria-required={true}
              onChange={handleChange}
              value={objector.postalAddress}
              helperText={
                objectorHelper.postalAddress.errorText !== "" &&
                objectorHelper.postalAddress.errorText !== null
                  ? objectorHelper.postalAddress.errorText
                  : objectorHelper.postalAddress.helperText
              }
              required={objectorHelper.postalAddress.required}
              hasError={objectorHelper.postalAddress.hasError}
              onBlur={onBlur}
              maxLength={objectorHelper.postalAddress.length}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="4" lg="4" md="4" sm="4" xs="4" className="mb-3">
            <MaterialInputText
              id="suburb"
              label="Suburb"
              name="suburb"
              aria-required={true}
              onChange={handleChange}
              value={objector.suburb}
              helperText={
                objectorHelper.suburb.errorText !== "" &&
                objectorHelper.suburb.errorText !== null
                  ? objectorHelper.suburb.errorText
                  : objectorHelper.suburb.helperText
              }
              required={objectorHelper.suburb.required}
              hasError={objectorHelper.suburb.hasError}
              onBlur={onBlur}
              maxLength={objectorHelper.suburb.length}
            />
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="4" xs="4" className="mb-3">
            <MaterialInputText
              id="state"
              label="State"
              name="state"
              aria-required={true}
              onChange={handleChange}
              value={objector.state}
              helperText={
                objectorHelper.state.errorText !== "" &&
                objectorHelper.state.errorText !== null
                  ? objectorHelper.state.errorText
                  : objectorHelper.state.helperText
              }
              required={objectorHelper.state.required}
              hasError={objectorHelper.state.hasError}
              onBlur={onBlur}
              maxLength={objectorHelper.state.length}
            />
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="4" xs="4" className="mb-3">
            <MaterialInputText
              id="postcode"
              label="Postcode"
              name="postcode"
              aria-required={true}
              onChange={handleChange}
              value={objector.postcode}
              helperText={
                objectorHelper.postcode.errorText !== "" &&
                objectorHelper.postcode.errorText !== null
                  ? objectorHelper.postcode.errorText
                  : objectorHelper.postcode.helperText
              }
              required={objectorHelper.postcode.required}
              hasError={objectorHelper.postcode.hasError}
              onBlur={onBlur}
              maxLength={objectorHelper.postcode.length}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="9" lg="9" md="9" sm="9" xs="9" className="mb-3">
            <MaterialInputText
              id="email"
              label="Email"
              name="email"
              aria-required={true}
              onChange={handleChange}
              value={objector.email}
              helperText={
                objectorHelper.email.errorText !== "" &&
                objectorHelper.email.errorText !== null
                  ? objectorHelper.email.errorText
                  : objectorHelper.email.helperText
              }
              required={objectorHelper.email.required}
              hasError={objectorHelper.email.hasError}
              onBlur={onBlur}
            />
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <MaterialInputText
              id="mobile"
              label="Phone"
              name="mobile"
              aria-required={true}
              onChange={handleChange}
              value={objector.mobile}
              helperText={
                objectorHelper.mobile.errorText !== "" &&
                objectorHelper.mobile.errorText !== null
                  ? objectorHelper.mobile.errorText
                  : objectorHelper.mobile.helperText
              }
              required={objectorHelper.mobile.required}
              hasError={objectorHelper.mobile.hasError}
              onBlur={onBlur}
              maxLength={objectorHelper.mobile.length}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <div className="float-left">
              <MDBBtn
                aria-labelledby="objector-page-label"
                className="button-style"
                onClick={backToHomePage}
              >
                Back
              </MDBBtn>
            </div>

            <div className="float-right">
              <MDBBtn
                aria-labelledby="objector-page-label"
                className="button-style"
                onClick={saveObjector}
                disabled={
                  objector.respondantType !== RESPONDENT_TYPE_AGENT
                    ? false
                    : objector.respondantType === RESPONDENT_TYPE_AGENT &&
                      objector.objectionAuthorisation === "false"
                    ? true
                    : false
                }
              >
                Next
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
//#endregion JSX code
export default ObjectorDetails;
