import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import MDBTextArea from "../../controls/MDBReact-InputTextArea";
import PropertyStore from "../../stores/propertyStore";
import leaseTerms from "../../Lists/LeaseTerms";
import Comparability from "../../Lists/Comparability";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import MaterialSelect from "../../controls/MaterialUI-Select";
import ModalPage from "../../controls/MDBReact-Modal";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import CommonStores from "../../stores/commonStore";
import "./SaleEvidenceDetails.scss";
import MaterialInputCurrency from "../../controls/MaterialUI-InputCurrency";

function SaleEvidenceDetails(props) {
  // getting the property details
  const [salesEvidenceText] = useState(CommonStores.getSalesEvidenceText());
  const [salesEvidenceHelper, setSalesEvidenceHelper] = useState(
    PropertyStore.getSalesEvidenceHelper()
  );
  const [modalProps] = useState({
    showModal: false,
    header: "Confirmation",
    message: "",
  });

  useEffect(() => {
    PropertyStore.addChangeListener(onchange);

    function onchange() {
      setSalesEvidenceHelper(PropertyStore.getSalesEvidenceHelper());
    }
    return () => PropertyStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, []);

  return (
    <>
      <MDBRow className="page-sub-headings mb-3">
        <MDBCol>
          <div id={"saleEvidenceId" + props.index}>
            Rental evidence {props.index + 1}
            <button
              className="float-right delete-sale-evidence"
              id={props.index}
              onClick={props.handleDelete}
            >
              <MDBIcon aria-label="Trash" icon="trash" />
            </button>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow className="property-details-background mb-3">
        <MDBCol>
          <div
            className="cms-content-property-details"
            dangerouslySetInnerHTML={{ __html: salesEvidenceText }}
          ></div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Address"
            name="address"
            value={props.salesEvidence.address}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={salesEvidenceHelper.address.errorText}
            required={salesEvidenceHelper.address.required}
            hasError={salesEvidenceHelper.address.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
            maxLength={props.salesEvidenceHelper.address.length}
          ></MaterialInputText>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Paste web address of an online listing"
            name="saleListingURL"
            value={props.salesEvidence.saleListingURL}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={salesEvidenceHelper.saleListingURL.errorText}
            required={salesEvidenceHelper.saleListingURL.required}
            hasError={salesEvidenceHelper.saleListingURL.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputText>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Lettable area (m²)"
            name="buildAreaM2"
            value={props.salesEvidence.buildAreaM2}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={salesEvidenceHelper.buildAreaM2.errorText}
            required={salesEvidenceHelper.buildAreaM2.required}
            hasError={salesEvidenceHelper.buildAreaM2.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputText>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
          <MaterialInputCurrency
            label="Gross rent ($)"
            name="leaseAmountPa"
            value={props.salesEvidence.leaseAmountPa}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={salesEvidenceHelper.leaseAmountPa.errorText}
            required={salesEvidenceHelper.leaseAmountPa.required}
            hasError={salesEvidenceHelper.leaseAmountPa.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputCurrency>
        </MDBCol>
        <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
          <MaterialSelect
            inputLabel="Lease term"
            name="leaseTermId"
            value={props.salesEvidence.leaseTermId}
            onSelectChange={(event) => props.handleChange(event, props.index)}
            list={leaseTerms}
            formHelperText={salesEvidenceHelper.leaseTermId.errorText}
            disabled={false}
            required={salesEvidenceHelper.leaseTermId.required}
            hasError={salesEvidenceHelper.leaseTermId.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialSelect>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Options"
            name="leaseOptions"
            value={props.salesEvidence.leaseOptions}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            required={salesEvidenceHelper.leaseOptions.required}
            hasError={salesEvidenceHelper.leaseOptions.hasError}
            helperText={salesEvidenceHelper.leaseOptions.errorText}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputText>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
          <Typography
            gutterBottom
            aria-label="salesEvidence-buildingConditionId"
          >
            Comparability
          </Typography>
          <Slider
            id="buildingConditionId"
            name="buildingConditionId"
            aria-labelledby="salesEvidence-buildingConditionId"
            step={1}
            min={1}
            max={3}
            onKeyDown={(event, value) => {
              props.sliderChange(
                event,
                value,
                props.index,
                "buildingConditionId"
              );
            }}
            onChange={(event, value) => {
              props.sliderChange(
                event,
                value,
                props.index,
                "buildingConditionId"
              );
            }}
            value={
              props.salesEvidence.buildingConditionId
                ? props.salesEvidence.buildingConditionId
                : "2"
            }
            marks={Comparability}
            required={salesEvidenceHelper.buildingConditionId.required}
            hasError={salesEvidenceHelper.buildingConditionId.hasError}
          />
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6"></MDBCol>
      </MDBRow>

      <MDBRow className="static-text-contents mb-3">
        <MDBCol>
          Detail the comparison between this example and the subject property:
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol>
          <MDBTextArea
            label="Comparability to your property"
            name="saleDescription"
            value={props.salesEvidence.saleDescription}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            hasError={salesEvidenceHelper.saleDescription.hasError}
            required={salesEvidenceHelper.saleDescription.required}
          ></MDBTextArea>
        </MDBCol>
      </MDBRow>

      {modalProps.showModal && (
        <ModalPage
          header={modalProps.header}
          showModal={modalProps.showModal}
          message={modalProps.message}
        />
      )}
    </>
  );
}

export default SaleEvidenceDetails;
