let _salesEvidenceHelper = {
  address: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Address",
    errorText: "",
    length: 200
  },
  saleListingURL: {
    required: false,
    hasError: false,
    helperText: "",
    type: "url",
    fieldName: "Sale Listing URL",
    errorText: "",
  },
  landAreaM2: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    isPositiveOnly: true,
    fieldName: "Land Area",
    errorText: "",
    max: 1000000,
    min: 0,
  },
  buildAreaM2: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    isPositiveOnly: true,
    fieldName: "Build Area",
    errorText: "",
    max: 100000,
    min: 0,
  },
  saleDate: {
    required: false,
    hasError: false,
    helperText: "",
    type: "date",
    fieldName: "Sale Date",
    errorText: "",
  },
  salePrice: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Sale Price",
    errorText: "",
    max: 500000000,
    min: 1,
  },
  leaseAmountPa: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Lease Amount",
    errorText: "",
    max: 500000000,
    min: 1,
  },
  leaseTermId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Lease Term",
    errorText: "",
  },
  leaseOptions: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Lease Renewal Options",
    errorText: "",
  },
  buildingConditionId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "slider",
    fieldName: "Building Condition",
    errorText: "",
  },
  saleDescription: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Sale Description",
    errorText: "",
  },
};

class SalesEvidenceHelper {
  getSalesEvidenceHelper() {
    return _salesEvidenceHelper;
  }

  getFieldDetail(field) {
    let value = _salesEvidenceHelper[field];
    return value;
  }
}

// we need to instantiate the store
const salesEvidenceHelper = new SalesEvidenceHelper();

export default salesEvidenceHelper;
