import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import MaterialRadio from "../../controls/MaterialUI-Radio";
import YesNoList from "../../Lists/YesNo";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import Attachments from "../Attachments/Attachments";
import Constants from "../Constants";

function TenancyInformation(props) {
  const TIS_FILLED_YES = "1";
  const TIS_FILLED_NO = "2";  
  const Max_Files_TIS = 1;
  const [objector] = useState(props.objector);
  const [property, setProperty] = useState(props.property);
  const [objectionDetailsHelper, setObjectionDetailsHelper] = useState(
    props.objectionDetailsHelper
  );

  //#region UseEffect Hook
  useEffect(() => {
    setProperty(props.property);
    setObjectionDetailsHelper(props.objectionDetailsHelper);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.property]);
  //#endregion UseEffect Hook

  return (
    <>
      <MDBRow className="page-sub-headings mb-3">
        <MDBCol>Tenancy information schedule (TIS)</MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="12" xs="12" className="mb-3">
          <MaterialRadio
            label={
              "Have you previously filled out a Tenancy information schedule (TIS)?"
            }
            list={YesNoList}
            name="tisPreviouslyFilled"
            value={property.tisPreviouslyFilled}
            aria-required={true}
            radioChanged={props.radioChanged}
            required={objectionDetailsHelper.tisPreviouslyFilled.required}
            error={objectionDetailsHelper.tisPreviouslyFilled.hasError}
            helperText={objectionDetailsHelper.tisPreviouslyFilled.errorText}
            onBlur={props.onBlur}
          ></MaterialRadio>
        </MDBCol>
      </MDBRow>
      {property.tisPreviouslyFilled !== "" &&
        property.tisPreviouslyFilled === TIS_FILLED_YES && (
          <>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="12" xs="12" className="mb-3">
                <MaterialInputText
                  label="Year TIS Supplied"
                  name="yearTenancyInformationScheduleSupplied"
                  value={property.yearTenancyInformationScheduleSupplied}
                  onChange={props.handleChange}
                  disabled={false}
                  required={
                    objectionDetailsHelper
                      .yearTenancyInformationScheduleSupplied.required
                  }
                  hasError={
                    objectionDetailsHelper
                      .yearTenancyInformationScheduleSupplied.hasError
                  }
                  helperText={
                    objectionDetailsHelper
                      .yearTenancyInformationScheduleSupplied.errorText
                  }
                  onBlur={props.onBlur}
                ></MaterialInputText>
              </MDBCol>
            </MDBRow>
          </>
        )}

      {property.tisPreviouslyFilled !== "" &&
        property.tisPreviouslyFilled === TIS_FILLED_NO && (
          <>
            <Attachments
              propertyId={property.propertyId}
              objectorId={objector.objectorId}
              label="Please attach a copy of your tenancy information schedule (TIS)"
              heading=""
              errorText={objectionDetailsHelper.tisAttachments.errorText}
              attachmentType={Constants.TIS_ATTACHMENTS}
              handleAttachmentUploaded={props.handleAttachmentUploaded}
              disabled={property.tisAttachments}
              attachmentDeleted={props.attachmentDeleted}
              buttonText="Add TIS Attachment"
              filesLimit={Max_Files_TIS}
              tisAttachments={property.tisAttachments}
            />
          </>
        )}
    </>
  );
}

export default TenancyInformation;
