import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import PropertyStore from "../stores/propertyStore";
import { getRequest, saveRequest, deleteRequest } from "../api/apiRequest";
import dwellingTypes from "../Lists/DwellingTypes";
import { getNamefromList } from "../utilities/getNameFromList";
import { currencyToInteger } from "../utilities/currencyToInteger";
import landAreaUnits from "../Lists/landAreaUnits";
import constructionMaterials from "../Lists/ConstructionMaterials";
import PropertyConditions from "../Lists/PropertyConditions";
import leaseTerms from "../Lists/LeaseTerms";
import reviewFrequencies from "../Lists/ReviewFrequencies";
import reviewBasis from "../Lists/ReviewBasis";
import propertyTypes from "../Lists/PropertyTypes";
import objectionReasons from "../Lists/ObjectionReasons";
import AirConditionings from "../Lists/AirConditionings";
import eraBuilt from "../Lists/EraBuilt";
import YesNoList from "../Lists/YesNo";
import { dateFormat } from "../utilities/dateFormat";

export function getPropertiesByObjectorId(objectorId) {
  const apiUrl = "/objector/" + encodeURIComponent(objectorId) + "/property";
  return getRequest(apiUrl, false).then((response) => {
    if (response && response.length > 0) {
      let properties = [];
      response.forEach((property) => {
        let newProperty = PropertyStore.getNewProperty();
        newProperty = Object.assign(newProperty, property);
        newProperty.objectorId = objectorId;
        properties.push(newProperty);
      });
      dispatchSaveProperties(properties, objectorId);
    }
  });
}

export function dispatchSaveProperty(property, objectorId) {
  dispatcher.dispatch({
    actionType: actionTypes.SAVE_PROPERTY,
    objectorId: objectorId,
    property: property,
  });
}

export function dispatchSaveProperties(properties, objectorId) {
  dispatcher.dispatch({
    actionType: actionTypes.SAVE_PROPERTIES,
    objectorId: objectorId,
    properties: properties,
  });
}

export function submitPropertyDispatch(property) {
  dispatcher.dispatch({
    actionType: actionTypes.SUBMIT_PROPERTY,
    property: property,
  });
}

export function dispatchUpdateProperty(property) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_PROPERTY,
    property: property,
  });
}

export function dispatchSetPropertyId(propertyId) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_PROPERTY_ID,
    propertyId: propertyId,
  });
}

export function dispatchUpdateProperties(objectorId, properties) {
  dispatcher.dispatch({
    actionType: actionTypes.SAVE_PROPERTIES,
    objectorId: objectorId,
    properties: properties,
  });
}

export function verifyProperty(property) {
  const apiUrl =
    "/property?council=" +
    encodeURIComponent(property.councilId) +
    "&sv=" +
    currencyToInteger(property.siteValue) +
    "&civ=" +
    currencyToInteger(property.capitalImprovedValue) +
    "&nav=" +
    currencyToInteger(property.netAnnualValue) +
    "&assessment=" +
    encodeURIComponent(property.assessmentNumber);

  return getRequest(apiUrl, false);
}

export function postProperty(property, objectorId) {
  let apiUrl = "/objector/" + encodeURIComponent(objectorId) + "/property";
  property.councilId = encodeURIComponent(property.councilId.toString());
  return saveRequest(apiUrl, "POST", property);
}

export function updateObjectionDetailsOfProperty(property, objectorId) {
  let apiUrl = "/objector/" + objectorId + "/property/" + property.propertyId;

  property.objectionReason = getNamefromList(
    objectionReasons,
    property.objectionReasonId
  );

  property.objectionReason = getNamefromList(
    objectionReasons,
    property.objectionReasonId
  );

  property.propertyType = getNamefromList(
    propertyTypes,
    property.propertyTypeId
  );

  property.dwellingType = getNamefromList(
    dwellingTypes,
    property.dwellingTypeId
  );

  property.landAreaUnit = getNamefromList(
    landAreaUnits,
    property.landAreaUnitID
  );

  property.constructionMaterial = getNamefromList(
    constructionMaterials,
    property.constructionMaterialId
  );

  property.buildingCondition = getNamefromList(
    PropertyConditions,
    property.buildingConditionId
  );

  property.leaseTermDescription = getNamefromList(
    leaseTerms,
    property.leaseTerm
  );

  property.reviewFrequencyDescription = getNamefromList(
    reviewFrequencies,
    property.reviewFrequency
  );

  property.reviewBasisDescription = getNamefromList(
    reviewBasis,
    property.reviewBasis
  );

  property.airConditioning = getNamefromList(
    AirConditionings,
    property.airConditioningId
  );

  let elevatorInBuilding = YesNoList.find(
    (x) => x.id === property.elevatorInBuilding
  );
  if (elevatorInBuilding) {
    property.elevatorInBuildingDescription = elevatorInBuilding.value;
  }

  let tisPreviouslyFilled = YesNoList.find(
    (x) => x.id === property.tisPreviouslyFilled
  );
  if (tisPreviouslyFilled) {
    property.tisPreviouslyFilledDescription = tisPreviouslyFilled.value;
  }

  let updatedProperty = {};
  updatedProperty = Object.assign(updatedProperty, property);
  updatedProperty.leaseCommenced = dateFormat(
    updatedProperty.leaseCommenced,
    "yyyy-mm-dd"
  );
  updatedProperty.rateNoticeReceived = dateFormat(
    updatedProperty.rateNoticeReceived,
    "yyyy-mm-dd"
  );

  return saveRequest(apiUrl, "PUT", updatedProperty);
}

export function deletePropertyAPI(propertyId, objectorId) {
  let apiUrl =
    "/objector/" +
    encodeURIComponent(objectorId) +
    "/property/" +
    encodeURIComponent(propertyId);
  return deleteRequest(apiUrl);
}

export function dispatchDeleteProperty(propertyId) {
  dispatcher.dispatch({
    actionType: actionTypes.DELETE_PROPERTY,
    propertyId: propertyId,
  });
}

export function submitProperty(property) {
  let url =
    "/objector/" +
    encodeURIComponent(property.objectorId) +
    "/property/" +
    encodeURIComponent(property.propertyId);

  property.objectionReason = getNamefromList(
    objectionReasons,
    property.objectionReasonId
  );

  property.landAreaUnit = getNamefromList(
    landAreaUnits,
    property.landAreaUnitID
  );

  property.propertyType = getNamefromList(
    propertyTypes,
    property.propertyTypeId
  );

  property.dwellingType = getNamefromList(
    dwellingTypes,
    property.dwellingTypeId
  );

  property.landAreaUnit = getNamefromList(
    landAreaUnits,
    property.landAreaUnitID
  );

  property.constructionMaterial = getNamefromList(
    constructionMaterials,
    property.constructionMaterialId
  );

  property.buildingCondition = getNamefromList(
    PropertyConditions,
    property.buildingConditionId
  );

  property.leaseTermDescription = getNamefromList(
    leaseTerms,
    property.leaseTerm
  );

  property.reviewFrequencyDescription = getNamefromList(
    reviewFrequencies,
    property.reviewFrequency
  );

  property.reviewBasisDescription = getNamefromList(
    reviewBasis,
    property.reviewBasis
  );

  property.yearBuiltDescription = getNamefromList(eraBuilt, property.yearBuilt);

  let elevatorInBuilding = YesNoList.find(
    (x) => x.id === property.elevatorInBuilding
  );
  if (elevatorInBuilding) {
    property.elevatorInBuildingDescription = elevatorInBuilding.value;
  }

  let tisPreviouslyFilled = YesNoList.find(
    (x) => x.id === property.tisPreviouslyFilled
  );
  if (tisPreviouslyFilled) {
    property.tisPreviouslyFilledDescription = tisPreviouslyFilled.value;
  }

  property.status = "submitted";

  let updatedProperty = {};
  updatedProperty = Object.assign(updatedProperty, property);
  updatedProperty.leaseCommenced = dateFormat(
    updatedProperty.leaseCommenced,
    "yyyy-mm-dd"
  );
  updatedProperty.rateNoticeReceived = dateFormat(
    updatedProperty.rateNoticeReceived,
    "yyyy-mm-dd"
  );

  return saveRequest(url, "PUT", updatedProperty);
}

export function updatePropertyIdentificationHelper(
  propertyIdentificationHelper
) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_PROPERTY_IDENTIFICATION_HELPER,
    propertyIdentificationHelper: propertyIdentificationHelper,
  });
}

export function updatePropertyDetailsHelper(propertyDetailsHelper) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_PROPERTY_DETAILS_HELPER,
    propertyDetailsHelper: propertyDetailsHelper,
  });
}

export function updateSalesEvidenceHelper(salesEvidenceHelper) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_SALES_EVIDENCE_HELPER,
    salesEvidenceHelper: salesEvidenceHelper,
  });
}

export function getContactDetailsFromCMS() {
  const url =
    new URL("/contact-details.json", process.env.REACT_APP_CONTENT_URL).href;
    
  return fetch(url, {
    method: "GET",
  })
  .then(response => response.json());
}

export function initialiseProperty() {
  PropertyStore.initialiseProperty();
}

export function setPropertyLoginError(propertyLoginError) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_PROPERTY_LOGIN_ERROR,
    propertyLoginError: propertyLoginError,
  });
}
