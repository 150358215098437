export function FocusOnErrorControl(helper) {
  for (const item in helper) {
    if (helper[item].hasError) {
      let element = document.getElementsByName(item);
      if (element) {
        ScrollTo(element);
      }
      return false;
    }
  }
}

export function ScrollTo(element) {
  if (!element) return;

  let rect = null;
  if (element.length > 0) {
    rect = element[0].getBoundingClientRect();
  } else if (element.focus) {
    rect = element.getBoundingClientRect();
  }
  if (rect) {
    let left = rect.left + window.scrollX;
    let top = rect.top + window.scrollY;

    window.scrollTo({
      top: top,
      left: left,
      behavior: "smooth",
    });
  }
}
