//#region imports
import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import AttachmentStore from "../../stores/attachmentsStore";
import * as AttachmentActions from "../../actions/attachmentsActions";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ClearIcon from "@material-ui/icons/Clear";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import {
  deleteRequest,
  deleteUpload,
  uploadRequest,
  getAttachmentUrlRequest,
} from "../../api/apiRequest";
import Constants from "../Constants";
//#endregion imports

function Attachments(props) {
  var propertyId = props.propertyId;
  var objectorId = props.objectorId;

  const [filesState, setFilesState] = useState({
    open: false,
    files: [],
  });
  const [attachments, setAttachments] = useState(
    AttachmentStore.getAttachmentsByPropertyId(propertyId, props.attachmentType)
  );
  const [attachmentDisabled, setAttachmentDisabled] = useState(props.disabled);

  useEffect(() => {
    AttachmentStore.addChangeListener(onAttachmentchange);

    document.title = "Object to Rating Valuation - Property Details";

    function onAttachmentchange() {
      setAttachments(
        AttachmentStore.getAttachmentsByPropertyId(
          propertyId,
          props.attachmentType
        )
      );
    }

    return () => AttachmentStore.removeChangeListener(onAttachmentchange); //cleans up on unmount of component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAttachmentDisabled(props.disabled);
  }, [props.disabled]);

  //#region Change event handlers

  function handleClose() {
    setFilesState({
      files: [],
      open: false,
    });
  }

  function handleSave(files) {
    //Saving files to state for further use and closing Modal.
    setFilesState({
      files: files,
      open: false,
    });

    fileHandleChange(files);
  }

  function handleOpen() {
    setFilesState({
      open: true,
    });
  }

  function fileHandleChange(files) {
    if (!files) return;

    startLoader();
    for (let i = 0; i < files.length; i++) {
      let apiUrl =
        "/objector/" +
        encodeURIComponent(objectorId) +
        "/property/" +
        encodeURIComponent(propertyId) +
        "/attachment";

      getAttachmentUrlRequest(apiUrl, "POST", {
        mimeType: files[i].type,
        fileName: files[i].name,
      }).then((result) => {
        if (result) {
          uploadRequest(result.signedURL, "PUT", files[i]).then(() => {
            // we are not expecting a response from upload to signed URL
            let attachments = [];
            let file = {
              propertyId: propertyId,
              attachmentId: result.attachmentId,
              fileName: files[i].name,
              mimeType: files[i].type,
              attachmentType: props.attachmentType,
            };
            attachments.push(file);
            props.handleAttachmentUploaded(file);

            AttachmentActions.dispatchSaveAttachments(attachments);
            if (i === files.length - 1) {
              stopLoader();
            }
          });
        }
      });
    }
  }

  function deleteAttachment(event, attachment) {
    startLoader();

    if (attachment && attachment.attachmentId) {
      let apiUrl =
        "/objector/" +
        objectorId +
        "/property/" +
        propertyId +
        "/attachment/" +
        attachment.attachmentId;

      deleteRequest(apiUrl).then((response) => {
        deleteUpload(response.signedURL).then((result) => {
          // Updating the Flux store by removing the deleted attachment
          AttachmentActions.dispatchDeleteAttachments(attachment.attachmentId);

          // if attach type is TIS, after delete, set the disable to ""
          props.attachmentDeleted(props.attachmentType);

          setTimeout(() => {
            stopLoader();
          }, 100);
        });
      });
    }
  }

  return (
    <>
      <MDBRow className="property-details-background">
        <MDBCol>
          {props.heading !== "" && (
            <h5>
              <strong>{props.heading}</strong>
            </h5>
          )}
          {props.label}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol>
          <div className="attachment-section">
            <div className="new-attachment" id="attachment-button">
              <div>
                <Button
                  aria-labelledby="attachment-button"
                  onClick={handleOpen}
                  className="button-style"
                  disabled={attachmentDisabled}
                >
                  {props.buttonText} <AttachmentIcon md-36 />
                </Button>
                <DropzoneDialog
                  filesLimit={props.filesLimit}
                  acceptedFiles={[
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                    "image/bmp",
                    "image/gif",
                    "image/tiff",
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-excel",
                    "image/svg+xml",
                    "text/plain",
                  ]}
                  alertSnackbarProps={false}
                  clearOnUnmount={true}
                  open={filesState.open}
                  onSave={handleSave}
                  maxFileSize={5000000}
                  onClose={handleClose}
                  showFileNames={true}
                />
              </div>
            </div>
            <div className="existing-attachments">
              {props.errorText && (
                <div className="required-field-error">
                  <strong>{props.errorText}</strong>
                </div>
              )}

              {props.attachmentType === Constants.TIS_ATTACHMENTS &&
              !props.tisAttachments
                ? "No files uploaded. Only one file is allowed"
                : null}

              {props.attachmentType === Constants.PROPERTY_ATTACHMENTS
                ? attachments.length > 0 && attachments[0].attachmentId !== ""
                  ? "Uploaded Files:"
                  : "No files uploaded."
                : null}

              {attachments.length > 0 &&
                attachments.map((value, index) => {
                  return (
                    <>
                      {value.attachmentId && value.attachmentId !== "" && (
                        <div>
                          <ClearIcon
                            md-36
                            onClick={(event) => deleteAttachment(event, value)}
                          />{" "}
                          &nbsp; &nbsp;
                          {value.fileName} &ensp;{" "}
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default Attachments;
