const propertyCategories = [
  { id: "0", name: "0 - No Code Assigned" },
  { id: "1-1100", name: "1-1100 - House" },
  { id: "1-1119", name: "1-1119 - House Unfinished" },
  { id: "1-1120", name: "1-1120 - Residential Rooms" },
  { id: "1-1130", name: "1-1130" },
  { id: "1-1140", name: "1-1140 - Residential Carpark" },
  { id: "1-1141", name: "1-1141 - Residential Parking Lot" },
  { id: "1-1142", name: "1-1142" },
  { id: "1-1150", name: "1-1150 - Residential Carport" },
  { id: "1-1157", name: "1-1157" },
  { id: "1-1160", name: "1-1160 - Residential Garage" },
  { id: "1-1165", name: "1-1165 - Residential Tool Shed" },
  { id: "1-1170", name: "1-1170 - Residential Shed" },
  { id: "1-1190", name: "1-1190 - Residential Tennis Court" },
  { id: "1-1195", name: "1-1195 - Residential Swimming Pool" },
  { id: "1-1300", name: "1-1300 - Home Unit" },
  { id: "1-1319", name: "1-1319 - Home Unit Unfinished" },
  { id: "1-1400", name: "1-1400 - Residential Flat" },
  { id: "1-1500", name: "1-1500 - Lodging House" },
  { id: "1-1710", name: "1-1710 - Community Housing" },
  { id: "1-1720", name: "1-1720 - Student Accomodation" },
  { id: "1-1750", name: "1-1750 - Convent" },
  { id: "1-1760", name: "1-1760 - Nursing Home" },
  { id: "2-2100", name: "2-2100 - Shop" },
  { id: "2-2109", name: "2-2109" },
  { id: "2-2119", name: "2-2119 - Shop Unfinished" },
  { id: "2-2160", name: "2-2160 - Showcase" },
  { id: "2-2310", name: "2-2310 - Restaurant" },
  { id: "2-2490", name: "2-2490 - Massage Parlour" },
  { id: "3-2500", name: "3-2500 - Office" },
  { id: "3-2519", name: "3-2519 - Office Unfinished" },
  { id: "4-1600", name: "4-1600 - Hostel" },
  { id: "4-1810", name: "4-1810 - Hotel Without Accomodation" },
  { id: "4-1815", name: "4-1815 - Hotel With Accomodation" },
  { id: "4-1820", name: "4-1820 - Motel" },
  { id: "4-1825", name: "4-1825 - Serviced Apartment" },
  { id: "4-1834", name: "4-1834 - Holiday Flat" },
  { id: "4-1835", name: "4-1835 - Holiday Home Unit" },
  { id: "4-1836", name: "4-1836 - Holiday House" },
  { id: "4-1850", name: "4-1850 - Backpacker Accommodation" },
  { id: "4-2000", name: "4-2000 - Warehouse" },
  { id: "4-2181", name: "4-2181 - Petrol Station" },
  { id: "4-2182", name: "4-2182 - Display Yard" },
  { id: "4-2210", name: "4-2210 - Bank" },
  { id: "4-2215", name: "4-2215 - Bank Automated Teller Machine" },
  { id: "4-2460", name: "4-2460 - Health Studio" },
  { id: "4-2495", name: "4-2495 - Artistic Studio" },
  { id: "4-2605", name: "4-2605 - Showroom" },
  { id: "4-2690", name: "4-2690 - Sign" },
  { id: "4-2691", name: "4-2691 - Naming Rights" },
  { id: "4-2695", name: "4-2695 - Storage Area" },
  { id: "4-2770", name: "4-2770 - Consulting Room" },
  { id: "4-4205", name: "4-4205 - Training Institution" },
  { id: "4-5630", name: "4-5630 - Function Facility" },
  { id: "4-5710", name: "4-5710 - Amusement Centre" },
  { id: "4-5730", name: "4-5730 - Club Room Commercial" },
  { id: "4-5740", name: "4-5740 - Night Club" },
  { id: "4-5750", name: "4-5750 - Cinema" },
  { id: "4-5770", name: "4-5770 - Theatre" },
  { id: "4-5810", name: "4-5810 - Hospital" },
  { id: "4-5890", name: "4-5890 - Laboratory" },
  { id: "4-6530", name: "4-6530 - Parking Lot Commercial" },
  { id: "4-6535", name: "4-6535 - Parking Bay Commercial" },
  { id: "4-6711", name: "4-6711" },
  { id: "4-6730", name: "4-6730 - Radio Station" },
  { id: "4-6760", name: "4-6760" },
  { id: "4-6790", name: "4-6790 - Transmission Mast" },
  { id: "4-6810", name: "4-6810 - Post Office" },
  { id: "4-7000", name: "4-7000 - Sports Building Commercial" },
  { id: "5-2920", name: "5-2920" },
  { id: "5-3000", name: "5-3000 - Factory" },
  { id: "5-3900", name: "5-3900 - Workshop" },
  { id: "6-6100", name: "6-6100 - Electricity Distribution Station" },
  { id: "6-6170", name: "6-6170 - Electricity Transformer Station" },
  { id: "6-6510", name: "6-6510" },
  { id: "7-9392", name: "7-9392 - Plant Nursery" },
  { id: "8-4100", name: "8-4100 - Land Vacant" },
  { id: "8-4110", name: "8-4110" },
  { id: "9-2580", name: "9-2580 - Plant Room" },
  { id: "9-2590", name: "9-2590 - Staff Facilities" },
  { id: "9-3000", name: "9-3000 - Development Site" },
  { id: "9-3100", name: "9-3100 - Building Unfinished" },
  { id: "9-3170", name: "9-3170 - Shed Non Residential" },
  { id: "9-3500", name: "9-3500 - Common Property" },
  { id: "9-3510", name: "9-3510 - Communal Area" },
  { id: "9-4190", name: "9-4190 - Derelict Building" },
  { id: "9-4320", name: "9-4320 - River Torrens & Moorings" },
  { id: "9-4500", name: "9-4500 - Reserve" },
  { id: "9-4520", name: "9-4520 - Park Lands & Botanic Gardens" },
  { id: "9-4525", name: "9-4525 - Park Lands Building" },
  { id: "9-4530", name: "9-4530 - Road Public Declared" },
  { id: "9-4531", name: "9-4531 - Road Public Not Declared" },
  { id: "9-4535", name: "9-4535 - Road Private Declared" },
  { id: "9-4536", name: "9-4536 - Road Private Not Declared" },
  { id: "9-4537", name: "9-4537 - Road Private Old" },
  { id: "9-5110", name: "9-5110 - State Executive & Legislative Premises" },
  { id: "9-5120", name: "9-5120 - Court & Gaol Premises" },
  { id: "9-5200", name: "9-5200 - School" },
  { id: "9-5210", name: "9-5210 - Child Care" },
  { id: "9-5241", name: "9-5241 - University" },
  { id: "9-5300", name: "9-5300 - Welfare Center" },
  { id: "9-5510", name: "9-5510 - Library" },
  { id: "9-5520", name: "9-5520 - Museum" },
  { id: "9-5530", name: "9-5530 - Art Gallery" },
  { id: "9-5570", name: "9-5570 - Zoo" },
  { id: "9-5595", name: "9-5595 - Monument" },
  { id: "9-5610", name: "9-5610 - Place of Worship" },
  { id: "9-5690", name: "9-5690 - Community Center" },
  { id: "9-5941", name: "9-5941 - Emergency Services" },
  { id: "9-6100", name: "9-6100" },
  { id: "9-6170", name: "9-6170" },
  { id: "9-6180", name: "9-6180" },
  { id: "9-6200", name: "9-6200 - Irrigation Infrastructure" },
  { id: "9-6400", name: "9-6400 - Railway Station" },
  { id: "9-6410", name: "9-6410 - Railway" },
  { id: "9-6510", name: "9-6510 - Bus Terminal" },
  { id: "9-6710", name: "9-6710 - Telecommunications Hub" },
  { id: "9-6711", name: "9-6711 - Telephone Exchange" },
  { id: "9-6760", name: "9-6760 - Television Station" },
  { id: "9-6970", name: "9-6970 - Cemetery" },
  { id: "9-6980", name: "9-6980 - Public Toilet" },
  { id: "9-7250", name: "9-7250 - Tennis Court Non Residential" },
  { id: "9-7900", name: "9-7900 - Sports Building Recreation" },
  { id: "9-9999", name: "9-9999 - IncludedWiths" },
];

export default propertyCategories;
