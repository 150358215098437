import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";

export function currentRoute(route) {
  dispatcher.dispatch({
    actionType: actionTypes.CURRENT_ROUTE,
    route: route,
  });
}

export function isRouting() {
  dispatcher.dispatch({
    actionType: actionTypes.IS_ROUTING,
  });
}

export function changeRouteVisibility(id, visible) {
  dispatcher.dispatch({
    actionType: actionTypes.CHANGE_ROUTE_VISIBILITY,
    id: id,
    visible: visible,
  });
}
