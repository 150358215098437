import React from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import MaterialInputCurrency from "../../controls/MaterialUI-InputCurrency";
import MaterialInputDate from "../../controls/MaterialUI-InputDate";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./NewPropertyIdentification.scss";

function NewPropertyIdentification(props) {
  return (
    <div id="new-property-identification">
      <MDBRow className="mb-3">
        <MDBCol>
          <MaterialInputText
            name="assessmentNumber"
            label="Assessment Number"
            aria-required={true}
            defaultValue=""
            helperText={
              props.propertyIdentificationHelper.assessmentNumber.helperText
            }
            required={
              props.propertyIdentificationHelper.assessmentNumber.required
            }
            value={props.value.assessmentNumber}
            onChange={props.onHandleChange}
            disabled={props.value.status !== "new" ? true : false}
            hasError={
              props.propertyIdentificationHelper.assessmentNumber.hasError
            }
            onBlur={props.onBlur}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="mb-3">
        <MDBCol>
          <div className="currency-item">
            <MaterialInputCurrency
              name="netAnnualValue"
              label="Annual Value ($)"
              aria-required={true}
              defaultValue=""
              required={
                props.propertyIdentificationHelper.netAnnualValue.required
              }
              helperText={
                props.propertyIdentificationHelper.netAnnualValue.helperText
              }
              value={props.value.netAnnualValue}
              onChange={props.onHandleChange}
              disabled={props.value.status !== "new" ? true : false}
              hasError={
                props.propertyIdentificationHelper.netAnnualValue.hasError
              }
              onBlur={props.onBlur}
              hidden={props.value.status !== "new" ? true : false}
            />
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow className="mb-3">
        <MDBCol>
          <div>
            <MaterialInputDate
              id="notice-date"
              name="rateNoticeReceived"
              label="Date of Notice"
              aria-required={true}
              value={props.value.rateNoticeReceived}
              onChange={(date) => {
                props.onDateChange(date, "rateNoticeReceived");
              }}
              required={
                props.propertyIdentificationHelper.rateNoticeReceived.required
              }
              hasError={
                props.propertyIdentificationHelper.rateNoticeReceived.hasError
              }
              helperText={
                props.propertyIdentificationHelper.rateNoticeReceived.helperText
              }
              disabled={props.value.status !== "new" ? true : false}
              hidden={props.value.status !== "new" ? true : false}
            />
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          {props.value.status !== "new" && (
            <CheckCircleIcon className="check-circle-icon-new" />
          )}
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MDBBtn
            className="button-style float-right"
            aria-labelledby="new-property-identification"
            type="submit"
            onClick={() => {
              props.onVerifyClick(props.value.status, props.value.propertyId);
            }}
            id={props.value.status}
            value={props.value.propertyId}
          >
            Verify
            <MDBIcon
              icon="chevron-circle-right"
              className="verify-icon-begin"
              hidden={props.value.status === "verified" ? false : true}
            />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  );
}

export default NewPropertyIdentification;
