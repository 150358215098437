import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import SalesEvidenceStore from "../stores/salesEvidenceStore";
import { deleteRequest, saveRequest, getRequest } from "../api/apiRequest";
import { getNamefromList } from "../utilities/getNameFromList";
import { getLabelFromSliderList } from "../utilities/getLabelFromSliderList";
import leaseTerms from "../Lists/LeaseTerms";
import Comparability from "../Lists/Comparability";

export function initialiseSalesEvidence() {
  SalesEvidenceStore.initialiseSalesEvidence();
}

export function getSalesEvidence(propertyId, objectorId) {
  let url =
    "/objector/" +
    encodeURIComponent(objectorId) +
    "/property/" +
    encodeURIComponent(propertyId) +
    "/salesevidence";

  return getRequest(url, true).then((response) => {
    if (response && response.length > 0) {
      response.forEach((sale) => {
        sale.propertyId = propertyId;
        dispatchUpdateSalesEvidence(sale);
      });
    }
  });
}

export function addSalesEvidence(propertyId) {
  dispatcher.dispatch({
    actionType: actionTypes.ADD_SALES_EVIDENCE,
    propertyId: propertyId,
  });
}

// we have two copies of this, one is SalesEvidenceActions (This one),
// the second on is in PropertyActions.
export function dispatchUpdateSalesEvidence(salesEvidence) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_SALE_EVIDENCE,
    salesEvidence: salesEvidence,
  });
}

export function updateAllSalesEvidence(salesEvidences) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_ALL_SALE_EVIDENCE,
    salesEvidences: salesEvidences,
  });
}

export function deleteSalesEvidenceBySalesEvidenceId(salesEvidenceId) {
  dispatcher.dispatch({
    actionType: actionTypes.DELETE_SALE_EVIDENCE_BY_PROPERTY_ID,
    salesEvidenceId: salesEvidenceId,
  });
}

export function saveSalesEvidence(propertyId, objectorId, sale) {
  let method =
    !sale.salesEvidenceId || sale.salesEvidenceId === "" ? "POST" : "PUT";
  let apiUrl =
    "/objector/" +
    encodeURIComponent(objectorId) +
    "/property/" +
    encodeURIComponent(propertyId) +
    "/salesevidence";

  let addSalesEvidenceId =
    sale.salesEvidenceId !== ""
      ? "/" + encodeURIComponent(sale.salesEvidenceId)
      : null;
  if (addSalesEvidenceId) {
    apiUrl = apiUrl + addSalesEvidenceId;
  }

  sale.leaseTerm = getNamefromList(leaseTerms, sale.leaseTermId);

  sale.buildingConditionId = sale.buildingConditionId.toString();
  sale.buildingCondition = getLabelFromSliderList(
    Comparability,
    sale.buildingConditionId
  );

  return saveRequest(apiUrl, method, sale);
}

export function deleteSalesEvidence(propertyId, objectorId, salesEvidenceId) {
  let apiUrl =
    "/objector/" +
    encodeURIComponent(objectorId) +
    "/property/" +
    encodeURIComponent(propertyId) +
    "/salesevidence/" +
    encodeURIComponent(salesEvidenceId);

  return deleteRequest(apiUrl);
}

export function dispatchDeleteSalesEvidence(salesEvidenceId) {
  dispatcher.dispatch({
    actionType: actionTypes.DELETE_SALE_EVIDENCE_BY_ID,
    salesEvidenceId: salesEvidenceId,
  });
}
