import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BreadcrumbStore from "../../stores/breadcrumbStore";
//import * as BreadcrumbActions from "../../actions/breadcrumbActions";
import { NavLink } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import "./Steppers.scss";

function Steppers(props) {
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState(
    BreadcrumbStore.getVisibeBreadcrumbRouters()
  );

  const [activeStep] = useState(getActiveRouteId());

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      textAlign: "center",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  useEffect(() => {
    BreadcrumbStore.addChangeListener(onchange);

    function onchange() {
      setBreadcrumbRoutes(BreadcrumbStore.getVisibeBreadcrumbRouters());
    }

    return () => BreadcrumbStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, []);

  // const classes = useStyles();

  const selectedRoute = {
    fontWeight: "bold",
    color: "#53565A",
    fontFamily: "VIC, 'Roboto', 'Helvetica Neue', 'Arial' !important",
  };

  const disabledStyle = {
    pointerEvents: "none",
    color: "#53565A",
    fontFamily: "VIC, 'Roboto', 'Helvetica Neue', 'Arial' !important",
  };

  const enabledStyle = {
    color: "#53565A",
    fontFamily: "VIC, 'Roboto', 'Helvetica Neue', 'Arial' !important",
  };

  const classes = useStyles();

  function getActiveRouteId() {
    let route = breadcrumbRoutes.filter(
      (x) => x.to === props.location.pathname
    );
    // we do a route[0].id - 1 because, we are excluding Home page from the steppers
    return route && route.length > 0 ? route[0].id - 1 : 0;
  }

  return (
    <>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {breadcrumbRoutes.map(
            (value, index) =>
              index !== 0 && (
                <Step key={value.desc}>
                  <StepLabel>
                    <NavLink
                      className="breadcrumb-text"
                      key={index.toString()}
                      activeStyle={selectedRoute}
                      style={
                        value.enabled === false ? disabledStyle : enabledStyle
                      }
                      name={value.name}
                      exact
                      to={value.to}
                    >
                      <h2>{value.enabled}</h2> {value.desc}
                    </NavLink>
                  </StepLabel>
                </Step>
              )
          )}
        </Stepper>
      </div>
    </>
  );
}

export default Steppers;
