export default {
  // application constants
  APPLICATION_SITE_ID_ADELAIDE: "5",
  APPLICATION_SITE_ID_VICTORIA: "7",
  APPLICATION_COUNCIL_ID_ADELAIDE: "808",

  // attachment constants
  PROPERTY_ATTACHMENTS: "PROPERTY_ATTACHMENTS",
  TIS_ATTACHMENTS: "TIS_ATTACHMENTS",

  // component constants
  OBJECTOR: "OBJECTOR",
  PROPERTIES: "PROPERTIES",
  PROPERTY_ID: "PROPERTY_ID",
  OBJECTION: "OBJECTION",
  SALE_EVIDENCE: "SALE_EVIDENCE",
  PROJECT_DETAILS: "PROJECT_DETAILS",
  ATTACHMENT: "ATTACHMENT",
  PROPERTY_IDENTIFICATION: "PROPERTY_IDENTIFICATION",
  PROPERTY_TYPE_ID: "PROPERTY_TYPE_ID",
  VALIDATE_OBJECTOR: "VALIDATE_OBJECTOR",
  LANDING_PAGE_TEXT: "LANDING_PAGE_TEXT",
  PROPERTY_VERIFICATION_MODAL_TEXT: "PROPERTY_VERIFICATION_MODAL_TEXT",
  OBJECTING_TO_VALUES_TEXT: "OBJECTING_TO_VALUES_TEXT",
  OBJECTON_COMMENTS_TEXT: "OBJECTON_COMMENTS_TEXT",
  PROPERTY_DETAILS_TEXT: "PROPERTY_DETAILS_TEXT",
  SALES_EVIDENCE_TEXT: "SALES_EVIDENCE_TEXT",
  OBJECTING_TO_VALUES_WARNING: "OBJECTING_TO_VALUES_WARNING",
  COUNCIL_RATES_NOTICE: "COUNCIL_RATES_NOTICE",
  PROPERTY_VERIFICATION_MAIN_TEXT: "PROPERTY_VERIFICATION_MAIN_TEXT",

  // BREADCRUMBS CONSTANTS
  BREADCRUMB_HOME: 0,
  BREADCRUMB_OBJECTOR: 1,
  BREADCRUMB_PROPERTY_IDENTIFICATION: 2,
  BREADCRUMB_OBJECTION: 3,
  BREADCRUMB_RENTAL: 4,
  BREADCRUMB_REVIEW: 5,
};
