import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import constants from "../components/Constants";
import {
  removeItem,
  retrieveItem,
  storeItem,
} from "../components/Session/Session";

const CHANGE_EVENT = "change";
let _salesEvidences = []; //private

let _salesEvidence = {
  salesEvidenceId: "",
  propertyId: "",
  status: "new",
  address: "",
  saleDate: "",
  salePrice: "",
  landAreaM2: "",
  buildAreaM2: "",
  buildingConditionId: "",
  buildingCondition: "",
  leaseAmountPa: "",
  leaseTermId: "-1",
  leaseTerm: "",
  leaseOptions: "",
  saleDescription: "",
  saleListingURL: "",
};

class SalesEvidenceStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getSalesEvidenceByPropertyId(propertyId) {
    if (propertyId === null) {
      // if propertyId is null, the caller component will be redirecting to
      // home page, this initialised sales evidence object is just to allow
      // the rendering of Sales evidence page smoothly, After Redirect
      let newSaleEvidence = {};
      newSaleEvidence = Object.assign(newSaleEvidence, _salesEvidence);
      return [newSaleEvidence];
    }

    if (!_salesEvidences || _salesEvidences.length === 0) {
      let value = retrieveItem(constants.SALE_EVIDENCE);
      if (value && value.length > 0) {
        _salesEvidences = value;
      }
    }

    let salesEvidence = _salesEvidences.filter(
      (x) => x.propertyId === propertyId.toString()
    );

    let newSaleEvidence = {};
    newSaleEvidence = Object.assign(newSaleEvidence, _salesEvidence);
    newSaleEvidence.propertyId = propertyId;

    return salesEvidence && salesEvidence.length > 0
      ? salesEvidence
      : [newSaleEvidence];
  }

  initialiseSalesEvidence() {
    _salesEvidences = [];
    _salesEvidence = {
      salesEvidenceId: "",
      propertyId: "",
      status: "new",
      address: "",
      saleDate: "",
      salePrice: "",
      landAreaM2: "",
      buildAreaM2: "",
      buildingConditionId: "",
      buildingCondition: "",
      leaseAmountPa: "",
      leaseTermId: "-1",
      leaseTerm: "",
      leaseOptions: "",
      saleDescription: "",
      saleListingURL: "",
    };
    removeItem(constants.SALE_EVIDENCE);
  }
}

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.ADD_SALES_EVIDENCE:
      let newSaleEvidence = {};
      newSaleEvidence = Object.assign(newSaleEvidence, _salesEvidence);
      newSaleEvidence.propertyId = action.propertyId;
      _salesEvidences.push(newSaleEvidence);
      removeItem(constants.SALE_EVIDENCE);
      storeItem(_salesEvidences, constants.SALE_EVIDENCE);
      store.emitChange();
      break;

    case actionTypes.DELETE_SALE_EVIDENCE_BY_ID:
      _salesEvidences = _salesEvidences.filter(
        (x) => x.salesEvidenceId !== action.salesEvidenceId
      );
      // updating localStorage
      removeItem(constants.SALE_EVIDENCE);
      storeItem(_salesEvidences, constants.SALE_EVIDENCE);
      store.emitChange();
      break;

    case actionTypes.UPDATE_SALE_EVIDENCE:
      // for current PropertyId, if there is just one record in _salesEvidences
      // we can assume that it is the
      let saleEvidencesByPropertyId = _salesEvidences.filter(
        (x) => x.propertyId === action.salesEvidence.propertyId
      );

      if (saleEvidencesByPropertyId) {
        if (saleEvidencesByPropertyId.length === 0) {
          // if there is not Sale EVidence for this property, just push it
          _salesEvidences.push(action.salesEvidence);
        } else if (saleEvidencesByPropertyId.length === 1) {
          let index = _salesEvidences.findIndex(
            (x) => x.propertyId === action.salesEvidence.propertyId
          );
          // if there is exactly 1 Sale evidence of this property,
          // replace the existing item of the array with the new one
          _salesEvidences.splice(index, 1, action.salesEvidence);
        } else if (saleEvidencesByPropertyId.length > 1) {
          // now that there are more than 1 sale evidences,
          // check if this record is actually an Update to
          // a record that has been stored in the DB before
          // using the salesEvidenceId, if it doesnt have
          // salesEvidenceId, means this record hasnt been
          // stored in the database so far

          // checking for 1st case first
          let saleEvidencesByPropertyIdAndSaleId = _salesEvidences.filter(
            (x) =>
              x.propertyId === action.salesEvidence.propertyId &&
              x.salesEvidenceId === action.salesEvidence.salesEvidenceId
          );

          if (saleEvidencesByPropertyIdAndSaleId.length > 0) {
            let index = _salesEvidences.findIndex(
              (x) =>
                x.propertyId === action.salesEvidence.propertyId &&
                x.salesEvidenceId === action.salesEvidence.salesEvidenceId
            );
            _salesEvidences.splice(index, 1, action.salesEvidence);
          } else {
            // means in many sale evidences for this PropertyID,
            // this one is being updated in flux for the first time
            let index = _salesEvidences.findIndex(
              (x) =>
                x.propertyId === action.salesEvidence.propertyId &&
                (x.salesEvidenceId === "" || x.salesEvidenceId == null)
            );
            _salesEvidences.splice(index, 1, action.salesEvidence);
          }
        }
      }

      // updating localStorage
      removeItem(constants.SALE_EVIDENCE);
      storeItem(_salesEvidences, constants.SALE_EVIDENCE);
      if (action.emitEvent) {
        store.emitChange();
      }
      break;

    case actionTypes.UPDATE_ALL_SALE_EVIDENCE:
      _salesEvidences = action.salesEvidences;
      removeItem(constants.SALE_EVIDENCE);
      storeItem(_salesEvidences, constants.SALE_EVIDENCE);
      store.emitChange();
      break;

    default:
      //do nothing
      break;
  }
});

// we need to instantiate the store
const store = new SalesEvidenceStore();

export default store;
