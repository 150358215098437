import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import constants from "../components/Constants";
import {
  retrieveItem,
  storeItem,
  removeItem,
} from "../components/Session/Session";
import ObjectorHelper from "../FieldHelper/objectorHelper";
import ValidateObjectorHelper from "../FieldHelper/validateObjectorHelper";

const CHANGE_EVENT = "change";
let _objector = {
  firstName: "",
  lastName: "",
  respondantType: "-1",
  professionId: "",
  profession: "",
  companyName: "",
  objectionAuthorisation: false,
  preferredCommunicationMethodId: "1",
  postalAddress: "",
  suburb: "",
  state: "",
  postcode: "",
  mobile: "",
  email: "",
  objectorId: "",
  sessionUrl: "",
  siteId: "",
};

let _objectorHelper = ObjectorHelper.getObjectorHelper();
let _ValidateObjectorHelper = ValidateObjectorHelper.getValidateObjectorHelper();

class ObjectorStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback); // change is the event
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getObjector() {
    // FIRST CHECK THE FLEX STORE OBJECTOR OBJECT
    if (_objector && _objector.objectorId !== "") {
      removeItem(constants.OBJECTOR);
      storeItem(_objector, constants.OBJECTOR);
      return _objector;
    }

    // now check if there is anything in local storage
    let objectorInSession = retrieveItem(constants.OBJECTOR);
    if (objectorInSession && objectorInSession.objectorId !== "") {
      _objector = objectorInSession;
    }

    return _objector;
  }

  getObjectorId() {
    return _objector && _objector.objectorId && _objector.objectorId !== ""
      ? _objector.objectorId
      : null;
  }

  // *******************************************
  // ************objector Helper****************
  // *******************************************
  getObjectorHelper() {
    return _objectorHelper;
  }

  getValidateObjectorHelper() {
    return _ValidateObjectorHelper;
  }
}

// we need to instantiate the store
const store = new ObjectorStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SAVE_OBJECTOR:
      _objector = action.objector;
      removeItem(constants.OBJECTOR);
      storeItem(action.objector, constants.OBJECTOR);
      store.emitChange();
      break;

    case actionTypes.LOAD_OBJECTOR:
      _objector = action.objector;
      store.emitChange();
      break;

    case actionTypes.UPDATE_OBJECTOR_HELPER:
      _objectorHelper = action.objectorHelper;
      store.emitChange();
      break;

    case actionTypes.UPDATE_VALIDATE_OBJECTOR_HELPER:
      _ValidateObjectorHelper = action.validateObjectorHelper;
      store.emitChange();
      break;

    default:
      break;
  }
});

export default store;
