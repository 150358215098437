let _objectionDetailsHelper = {
  objectionReasonId: {
    required: true,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Grounds for Objecting",
    errorText: "",
  },
  contendedSiteValue: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Contended Site Value",
    errorText: "",
  },
  contendedCapitalImprovedValue: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Contended Capital Improved Value",
    errorText: "",
  },
  contendedNetAnnualValue: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Contended Net Annual Value",
    errorText: "",
  },
  objectionComment: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Objection Comment",
    errorText: "",
  },
  propertyTypeId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Property Type",
    errorText: "",
  },
  dwellingTypeId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Dwelling Type",
    errorText: "",
  },
  constructionMaterialId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Construction Material",
    errorText: "",
  },
  buildAreaM2: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    isPositiveOnly: true,
    fieldName: "Build Area",
    errorText: "",
    max: 100000,
    min: 0,
  },
  numberRooms: {
    required: false,
    hasError: false,
    helperText: "",
    type: "number",
    fieldName: "Number of Rooms",
    errorText: "",
    min: 0,
    max: 100,
  },
  numberBedrooms: {
    required: false,
    hasError: false,
    helperText: "",
    type: "number",
    fieldName: "Number of Bedrooms",
    errorText: "",
    min: 0,
    max: 100,
  },
  numberBathrooms: {
    required: false,
    hasError: false,
    helperText: "",
    type: "number",
    fieldName: "Number of Bathrooms",
    errorText: "",
    min: 0,
    max: 100,
  },
  yearBuilt: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Year Built",
    errorText: "",
  },
  yearRenovation: {
    required: false,
    hasError: false,
    helperText: "",
    type: "number",
    fieldName: "Renovation Year",
    errorText: "",
    min: 1770, // needs to be an enum
    max: new Date().getFullYear(),
  },
  dwellingDescription: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Dwelling Description",
    errorText: "",
  },
  netLettableArea: {
    required: false,
    hasError: false,
    helperText: "",
    type: "number",
    fieldName: "Net Lettable Area",
    errorText: "",
    min: 0,
    max: 100000,
  },
  carParks: {
    required: false,
    hasError: false,
    helperText: "",
    type: "number",
    fieldName: "Car Parks",
    errorText: "",
    min: 0,
    max: 10000,
  },
  leaseAmountPA: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Lease Amount per year",
    errorText: "",
    max: 50000000000,
    min: 0,
  },
  leaseTerm: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Lease Term",
    errorText: "",
  },
  options: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Options",
    errorText: "",
  },
  leaseCommenced: {
    required: false,
    hasError: false,
    helperText: "",
    type: "date",
    fieldName: "Lease Commenced",
    errorText: "",
  },
  reviewFrequency: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Review Frequency",
    errorText: "",
  },
  reviewBasis: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Review Basis",
    errorText: "",
  },
  reviewDetail: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Review Detail",
    errorText: "",
  },
  tenantOutgoings: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Tenant Amount Paid ",
    errorText: "",
    max: 50000000000,
    min: 0,
  },
  ownerOutgoings: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Owner Amount Paid ",
    errorText: "",
    max: 50000000000,
    min: 0,
  },
  airConditioningId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "radio",
    fieldName: "Air conditioning",
    errorText: "",
  },
  elevatorInBuilding: {
    required: false,
    hasError: false,
    helperText: "",
    type: "radio",
    fieldName: "Elevator in building",
    errorText: "",
  },
  tisPreviouslyFilled: {
    required: false,
    hasError: false,
    helperText: "",
    type: "radio",
    fieldName: "TIS previously filled",
    errorText: "",
  },
  yearTenancyInformationScheduleSupplied: {
    required: false,
    hasError: false,
    helperText: "",
    type: "number",
    fieldName: "Year TIS Supplied",
    errorText: "",
    min: 1770,
    max: new Date().getFullYear(),
  },
  tisAttachments: {
    required: false,
    hasError: false,
    helperText: "",
    type: "attachment",
    fieldName: "TIS Attachment",
    errorText: "",
  },
};

class ObjectionDetailsHelper {
  getObjectionDetailsHelper() {
    return _objectionDetailsHelper;
  }

  getFieldDetail(field) {
    let value = _objectionDetailsHelper[field];
    return value;
  }
}

// we need to instantiate the store
const objectionDetailsHelper = new ObjectionDetailsHelper();

export default objectionDetailsHelper;
