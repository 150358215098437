const constructionMaterials = [
  { id: "-1", name: "Please select the construction material" },
  { id: "6", name: "Bluestone" },
  { id: "3", name: "Brick" },
  { id: "2", name: "Brick Veneer" },
  { id: "4", name: "Concrete" },
  { id: "5", name: "Metal" },
  { id: "7", name: "Timber" },
  { id: "1", name: "Weatherboard" },
  { id: "8", name: "None" },
  { id: "9", name: "Other" },
];

export default constructionMaterials;
