import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import logo from "../../images/coa-logo-white.svg";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  return (
    <>
      <div className="bottom-footer">
        <MDBContainer>
          <MDBRow className="footer-menu">
            <MDBCol
              xl="3"
              lg="3"
              md="3"
              sm="0"
              xs="0"
              className="d-none d-md-block"
            >
              <ul className="footer-menu-list">
                <li>
                  <a
                    href="https://www.cityofadelaide.com.au/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="footer-menu-item">Privacy </div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.cityofadelaide.com.au/copyright/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="footer-menu-item">Copyright</div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.cityofadelaide.com.au/disclaimer/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="footer-menu-item">Disclaimer </div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://cityofadelaide.com.au/web-accessibility"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="footer-menu-item">
                      Accessibility Statement{" "}
                    </div>
                  </a>
                </li>
              </ul>
            </MDBCol>
            <MDBCol xl="2" lg="2" md="2" sm="0" xs="0"></MDBCol>
            <MDBCol xl="1" lg="1" md="1" sm="0" xs="0"></MDBCol>
            <MDBCol xl="2" lg="2" md="2" sm="0" xs="0"></MDBCol>

            <MDBCol
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              className="footer-logo-group"
            >
              <div className="footer-logo">
                <Link to={"/"} className="footer-logo-link nav-link">
                  <img
                    className="logo"
                    src={logo}
                    alt="State Government Logo"
                  />
                </Link>
                <p className="footer-logo-text">
                  © {new Date().getFullYear()} City of Adelaide. All Rights
                  Reserved.
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </>
  );
}

export default Footer;
