import React from "react";

function MaterialRadio(props) {
  const radioErrorStyles = {
    color: "red",
  };

  return (
    <>
      <div>
        <span>{props.label}</span>

        {props.list.map((value, index) => {
          return (
            <div key={index + 1}>
              <div className="radio-option" key={index + 2}>
                <input
                  aria-labelledby={"label" + props.name + index + 1}
                  key={index}
                  type="radio"
                  id={props.name + index + 1}
                  name={props.name}
                  value={value.id}
                  checked={props.value === value.id}
                  onChange={props.radioChanged}
                  onBlur={props.onBlur}
                ></input>
                <label
                  htmlFor={props.name + index + 1}
                  id={"label" + props.name + index + 1}
                  className="radio-option-label"
                >
                  {value.name}
                </label>
              </div>
            </div>
          );
        })}

        <span style={radioErrorStyles} hidden={!props.error}>
          {props.helperText}
        </span>
      </div>
    </>
  );
}

export default MaterialRadio;
