//#region imports
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import PropertyNoticeDetails from "../PropertyNoticeDetails/PropertyNoticeDetails";
import NewPropertyIdentification from "../NewPropertyIdentification/NewPropertyIdentification";
import PropertyStore from "../../stores/propertyStore";
import CommonStore from "../../stores/commonStore";
import * as PropertyActions from "../../actions/propertyActions";
import * as AttachmentActions from "../../actions/attachmentsActions";
import * as SalesEvidenceActions from "../../actions/salesEvidenceActions";
import * as CommonActions from "../../actions/commonActions";
import ObjectorStore from "../../stores/objectorStore";
import PropertyIdentificationHelper from "../../FieldHelper/propertyIdentificationHelper";
import FormatCurrency from "../../utilities/currencyformatter";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import constants from "../Constants";
import {
  fieldValidator,
  fieldLevelValidation,
} from "../../utilities/fieldValidator";
import { daysBetweenTwoDates } from "../../utilities/daysBetweenTwoDates";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Steppers from "../../controls/Steppers/Steppers";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import { isEmailLink } from "../../utilities/isEmailLink";
import { ScrollTo } from "../../utilities/focusErrorControl";
import { dateFormat } from "../../utilities/dateFormat";
import "./PropertyIdentification.scss";
//#endregion imports

function PropertyIdentification(props) {
  const Adelaide_Rate_Notice_Id = "808";

  //#region check if the page is loaded using email link
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }
  //#endregion check if the page is loaded using email link

  //#region verifying if the loading of this component was using
  // routing or Did user entered a URL and opened this page
  var objectorId = null;
  if (props && props.location && props.location.objectorId) {
    objectorId = props.location.objectorId;
  } else {
    // now check the flux store which also checks the cache
    let objector = ObjectorStore.getObjector();
    if (objector && objector.objectorId !== "") {
      objectorId = objector.objectorId;
    } else {
      props.history &&
        props.history.push({
          pathname: "/ObjectorDetails",
        });
    }
  }
  //#endregion verifying the loading of this component

  //#region component declarations
  const MAX_FAILURE_ATTEMPTS = 3;
  const [
    propertyVerificationModalText,
    setPropertyVerificationModalText,
  ] = useState(CommonStore.getPropertyVerificationModalText());
  const [showDateError, setShowDateError] = useState(false);
  const [failureCount, setFailureCount] = useState(0);
  const [councilDetails, setCouncilDetails] = useState(null);
  const [councilRateNotice, setCouncilRateNotice] = useState(
    CommonStore.getCouncilRatesNotice(Adelaide_Rate_Notice_Id)
  );
  const [
    propertyIdentificationHelper,
    setPropertyIdentificationHelper,
  ] = useState(PropertyStore.getPropertyIdentificationHelper());
  const [properties, setProperties] = useState(
    PropertyStore.getProperties(objectorId)
  );
  const [newProperty, setNewProperty] = useState(
    PropertyStore.getNewPropertyAsArray()
  );
  const [verifyError, setVerifyError] = useState(false);
  const [propertyVerificationMainText] = useState(
    CommonStore.getPropertyVerificationMainText()
  );
  //#endregion Component declarations

  //#region useEffect State Hook
  useEffect(() => {
    PropertyStore.addChangeListener(onchange);

    document.title = "Object to Rating Valuation - Property Identification";
    // reset the council rates notice

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // get the council rate notice image
    if (!councilRateNotice) {
      CommonActions.getCouncilRatesNotice(Adelaide_Rate_Notice_Id)
        .then(content => {
          setCouncilRateNotice(content);
        });
    }

    if (!propertyVerificationModalText) {
      CommonActions.getPropertyVerificationModalText().then((response) => {
        setPropertyVerificationModalText(response);
      });
    }

    if (!councilDetails) {
      getContactDetailsFromCMS();
    }

    function onchange() {
      setProperties(PropertyStore.getProperties(objectorId));
      setPropertyIdentificationHelper(
        PropertyStore.getPropertyIdentificationHelper()
      );
    }
    return () => PropertyStore.removeChangeListener(onchange); //cleans up on unmount of component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion useEffect State Hook

  //#region Card style
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });
  const classes = useStyles();
  //#endregion Card Style

  //#region Events Handlers

  function handleChange(event) {
    let target = event.target;
    setNewProperty([{ ...newProperty[0], [target.name]: target.value }]);

    if (failureCount >= MAX_FAILURE_ATTEMPTS) {
      // Since this property is verified, we need to call API to get propertyId
      setFailureCount(0);
      setVerifyError(false);
    }
  }

  function onDateChange(date, name) {
    // if notice date is not valid, make the Date helper = Required
    let updateHelper = {};
    updateHelper = Object.assign(updateHelper, propertyIdentificationHelper);

    if (isNaN(date) || date === null || date > new Date()) {
      updateHelper[name].hasError = true;
      setNewProperty([{ ...newProperty[0], [name]: null }]);

      // since the date is either invalid or null or greater than today,
      // we will keep hiding or if currently visible, hide the out of time error
      setShowDateError(false);
    } else {
      setNewProperty([{ ...newProperty[0], [name]: date }]);
      updateHelper[name].hasError = !isNoticeDateValid(date);
    }

    setTimeout(() => {
      setPropertyIdentificationHelper(updateHelper);
    }, 100);
  }

  function getContactDetailsFromCMS() {
    const lgaCode = constants.APPLICATION_COUNCIL_ID_ADELAIDE

    PropertyActions.getContactDetailsFromCMS()
      .then(json => {
        json.forEach(x => {
          if (x.lga_code === lgaCode){
            setCouncilDetails(x.content.rendered);
            return false;
          }
          return true;
        });

        stopLoader();
      });
  }
  function deleteProperty({ currentTarget }) {
    // if the delete button clicked is for the new property that doesnt exist in database
    if (currentTarget.id === "0") {
      setNewProperty(PropertyStore.getNewPropertyAsArray());
      setFailureCount(0);
      setVerifyError(false);
    } else {
      PropertyActions.deletePropertyAPI(currentTarget.id, objectorId).then(
        (response) => {
          PropertyActions.dispatchDeleteProperty(currentTarget.id);
        }
      );
    }
  }

  function isNoticeDateValid(date) {
    let isValid = true;
    if (date !== null) {
      let daysDiffFromToday = daysBetweenTwoDates(new Date(), date);
      if (!isNaN(daysDiffFromToday)) {
        if (daysDiffFromToday > 65) {
          isValid = false;
        }
      }
    }

    setShowDateError(!isValid);

    setTimeout(() => {
      if (!isValid) {
        let element = document.getElementById("errorCouncilDetails");
        if (element) {
          ScrollTo(element);
        }
      }
    }, 100);

    return isValid;
  }

  // formatting currencies
  function onBlur(event) {
    fieldLevelValidation(
      propertyIdentificationHelper[event.target.name],
      event.target.value
    );
    PropertyActions.updatePropertyIdentificationHelper(
      propertyIdentificationHelper
    );

    let fieldDetails = PropertyIdentificationHelper.getFieldDetail(
      event.target.name
    );
    let finalValue = event.target.value;
    if (finalValue && finalValue !== "") {
      if (fieldDetails && fieldDetails.type === "currency") {
        finalValue = FormatCurrency.removeCurrencyFormat(event.target.value);
        if (fieldDetails.isPositiveOnly) {
          finalValue = finalValue < 0 ? finalValue * -1 : finalValue;
        }
        finalValue = FormatCurrency.formatCurrency(
          FormatCurrency.removeCurrencyFormat(finalValue)
        );
      }
    }
    let property = {
      ...newProperty[0],
      [event.target.name]: finalValue,
    };

    setNewProperty([property]);
  }

  function handleVerifyClick(propertyStatus, propertyId) {
    startLoader();
    // only properties with status = "new" will be going through verification
    if (propertyStatus === "new") {
      // check if all fields are valid
      let isFieldValidationSuccess = fieldValidator(
        constants.PROPERTY_IDENTIFICATION,
        newProperty[0],
        propertyIdentificationHelper
      );

      let rateNoticeDate = dateFormat(
        newProperty[0].rateNoticeReceived,
        "mm/dd/yyyy"
      );

      if (!isFieldValidationSuccess || !isNoticeDateValid(rateNoticeDate)) {
        setTimeout(() => {
          PropertyActions.updatePropertyIdentificationHelper(
            propertyIdentificationHelper
          );
          stopLoader();
        }, 100);
        return;
      }

      verifyNewProperty();
    } else if (propertyStatus === "verified") {
      // since the property is already verified there we need to get
      // GetAttachment and GetSalesEvidence and also calling a flux dispatcher
      // to store the currently selected PropertyId
      PropertyActions.dispatchSetPropertyId(propertyId);
      SalesEvidenceActions.getSalesEvidence(propertyId, objectorId);
      AttachmentActions.getAttachments(propertyId, objectorId);

      setTimeout(() => {
        props.history &&
          props.history.push({
            pathname: "/ObjectionDetails",
            propertyId: propertyId,
            objectorId: objectorId,
          });
        stopLoader();
      }, 1500);
    } else {
      stopLoader();
    }
  }
  //#endregion Events Handlers

  //#region Component API calling functions
  function verifyNewProperty() {
    PropertyActions.verifyProperty(newProperty[0]).then((response) => {
      if (response && response.errors && response.errors.length > 0) {
        setVerifyError(true);
        let count = failureCount + 1;
        setFailureCount(count);
      } else {
        newProperty[0].address = response.address;
        newProperty[0].objectorId = objectorId;
        newProperty[0].propertyTypeId = response.property_category.toString();
        newProperty[0].property_category = response.property_category.toString();
        newProperty[0].assessment_id = response.assessment_id.toString();
        newProperty[0].property_code = response.property_code.toString();
        // Since this property is verified, we need to call API to get propertyId
        setFailureCount(0);
        setVerifyError(false);
        postProperty();
        // set the focus on the Verified properties box
        let element = document.getElementById("verifyPropertyBackButton");

        if (element) {
          ScrollTo(element);
        }
      }
    });
  }

  function postProperty() {
    // since the property is verified, we can update the status to "verified"
    let value = "verified",
      column = "status";
    let verifiedProperty = {
      ...newProperty[0],
      [column]: value,
    };

    verifiedProperty.rateNoticeReceived = dateFormat(
      newProperty[0].rateNoticeReceived,
      "yyyy-mm-dd"
    );

    PropertyActions.postProperty(verifiedProperty, objectorId).then(
      (response) => {
        if (response && response.propertyId) {
          newProperty[0].propertyId = response.propertyId;
          newProperty[0].status = "verified";
          newProperty[0].objectorId = objectorId;

          // now we need to shoot a Flux action to add / update the property
          PropertyActions.dispatchSaveProperty(newProperty[0], objectorId);

          setNewProperty(PropertyStore.getNewPropertyAsArray());

          setTimeout(() => {
            stopLoader();
          }, 100);
        }
      }
    );
  }
  //#endregion Component API calling functions

  //#region helper functions
  function goToObjectorDetails() {
    props.history &&
      props.history.push({
        pathname: "/ObjectorDetails",
      });
  }
  //#endregion helper functions

  //#region JSX code
  return (
    <>
      <MDBContainer>
        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        {propertyVerificationMainText && (
          <>
            <MDBRow className="mb-3">
              <MDBCol>
                <div
                  className="cms-content"
                  dangerouslySetInnerHTML={{
                    __html: propertyVerificationMainText,
                  }}
                ></div>
              </MDBCol>
            </MDBRow>
          </>
        )}

        <MDBRow className="page-sub-heading rate-notice-img mb-3">
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3 mb-lg-0">
          { councilRateNotice &&
            <div
              className="cms-content"
              dangerouslySetInnerHTML={{ __html: councilRateNotice }}
            ></div>
          }
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3 mb-lg-0">
            {newProperty.map((value, index) => {
              return (
                <div className="new-property-entry" key={index.toString()}>
                  <Card
                    className={classes.root}
                    variant="outlined"
                    key={index.toString()}
                  >
                    <CardContent>
                      <NewPropertyIdentification
                        key={value.assessment_id}
                        value={value}
                        propertyIdentificationHelper={
                          propertyIdentificationHelper
                        }
                        onSelectChange={handleChange}
                        deleteProperty={deleteProperty}
                        onHandleChange={handleChange}
                        onDateChange={onDateChange}
                        onVerifyClick={handleVerifyClick}
                        onBlur={onBlur}
                      />
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </MDBCol>
        </MDBRow>

        {(verifyError || showDateError) && (
          <MDBRow className="property-values-error mt-3 mb-3">
            <MDBCol className="errorText">
              {showDateError && propertyVerificationModalText && (
                <div id="errorCouncilDetails">
                  <h4>Objection Out of Time</h4>
                  <br />
                  <div
                    className="cms-content"
                    dangerouslySetInnerHTML={{
                      __html: propertyVerificationModalText,
                    }}
                  ></div>
                  <br />
                </div>
              )}

              {verifyError && (
                <>
                  {
                    "The details that you entered could not be verified against council records."
                  }
                  {failureCount >= MAX_FAILURE_ATTEMPTS && (
                    <>
                      {
                        " If you continue to experience trouble verifying your property, please contact your council customer service helpline."
                      }
                    </>
                  )}
                </>
              )}
            </MDBCol>
          </MDBRow>
        )}

        {(failureCount >= MAX_FAILURE_ATTEMPTS || showDateError) &&
          councilDetails && (
            <>
              <MDBRow className="property-values-contact mb-3">
                <MDBCol
                  xl="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  className="errorText-council-details"
                >
                  <div
                    className="cms-content"
                    dangerouslySetInnerHTML={{ __html: councilDetails }}
                  ></div>
                </MDBCol>
              </MDBRow>
            </>
          )}

        <MDBRow className="mb-3">
          <MDBCol>
            {properties.map((value, index) => {
              return (
                <div key={index.toString()} className="mb-3">
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <PropertyNoticeDetails
                        key={value.assessmentID}
                        value={value}
                        propertyIdentificationHelper={
                          propertyIdentificationHelper
                        }
                        onSelectChange={handleChange}
                        deleteProperty={deleteProperty}
                        onHandleChange={handleChange}
                        onVerifyClick={handleVerifyClick}
                        onBlur={onBlur}
                      />
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <MDBBtn
              id="verifyPropertyBackButton"
              aria-labelledby="property-identification-label"
              className="button-style float-left"
              onClick={goToObjectorDetails}
            >
              <span className="add-property-text">Back</span>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
//#endregion JSX code

export default PropertyIdentification;
