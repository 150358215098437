import React, { useState, useEffect } from "react";
import BreadcrumbStore from "../../stores/breadcrumbStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import "./MobileLinearProgress.scss";

function MobileLinearProgress(props) {
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState(
    BreadcrumbStore.getVisibeBreadcrumbRouters()
  );

  useEffect(() => {
    BreadcrumbStore.addChangeListener(onchange);

    function onchange() {
      setBreadcrumbRoutes(BreadcrumbStore.getVisibeBreadcrumbRouters());
    }

    return () => BreadcrumbStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, []);

  function getActiveRouteId() {
    let route = breadcrumbRoutes.filter(
      (x) => x.to === props.location.pathname
    );
    return route && route.length > 0 ? route[0].id : 0;
  }

  return (
    <>
      {breadcrumbRoutes && breadcrumbRoutes.length > 0 && (
        <>
          <div className="linear-heading">
            <h5 className="page-header-text">
              {breadcrumbRoutes[getActiveRouteId() - 1].desc}
            </h5>
            <h5 className="linear-progress-numbers">
              Step{" "}
              {getActiveRouteId().toString() +
                " of " +
                (breadcrumbRoutes.length - 1).toString()}
            </h5>
          </div>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={Math.ceil(
                  (getActiveRouteId() / (breadcrumbRoutes.length - 1)) * 100
                )}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default MobileLinearProgress;
