//#region Imports
import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import constants from "../components/Constants";
import {
  removeItem,
  retrieveItem,
  storeItem,
} from "../components/Session/Session";
//#endregion Imports

//#region declarations
const CHANGE_EVENT = "change";
let _landingPageText = "";
let _propertyVerificationModalText = "";
let _objectingToValuesText = "";
let _objectionCommentsText = "";
let _propertyDetailsText = "";
let _salesEvidenceText = "";
let _objectingToValuesWarning = "";
let _councilRatesNotice = [];
let _propertyVerificationMainText = "";
//#endregion declarations

class CommonStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback); // change is the event
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getLandingPageText() {
    // first check in local flux store
    if (_landingPageText) {
      removeItem(constants.LANDING_PAGE_TEXT);
      storeItem(_landingPageText, constants.LANDING_PAGE_TEXT);
      return _landingPageText;
    }

    // check in session
    _landingPageText = retrieveItem(constants.LANDING_PAGE_TEXT);

    return _landingPageText;
  }

  getPropertyVerificationModalText() {
    // first check in flux store
    if (_propertyVerificationModalText) {
      return _propertyVerificationModalText;
    }

    // check in session
    _propertyVerificationModalText = retrieveItem(
      constants.PROPERTY_VERIFICATION_MODAL_TEXT
    );

    return _propertyVerificationModalText;
  }

  getObjectingToValuesText() {
    // first check in flux store
    if (_objectingToValuesText) {
      return _objectingToValuesText;
    }

    // check in session
    _objectingToValuesText = retrieveItem(constants.OBJECTING_TO_VALUES_TEXT);

    return _objectingToValuesText;
  }

  getObjectionCommentsText() {
    // first check in flux store
    if (_objectionCommentsText) {
      return _objectionCommentsText;
    }

    // check in session
    _objectionCommentsText = retrieveItem(constants.OBJECTON_COMMENTS_TEXT);
    return _objectionCommentsText;
  }

  getPropertyDetailsText() {
    // first check in flux store
    if (_propertyDetailsText) {
      return _propertyDetailsText;
    }

    // check in session
    _propertyDetailsText = retrieveItem(constants.PROPERTY_DETAILS_TEXT);
    return _propertyDetailsText;
  }

  getSalesEvidenceText() {
    // first check in flux store
    if (_salesEvidenceText) {
      return _salesEvidenceText;
    }

    _salesEvidenceText = retrieveItem(constants.SALES_EVIDENCE_TEXT);
    return _salesEvidenceText;
  }

  getObjectingToValuesWarning() {
    // first check in flux store
    if (_objectingToValuesWarning) {
      return _objectingToValuesWarning;
    }

    _objectingToValuesWarning = retrieveItem(
      constants.OBJECTING_TO_VALUES_WARNING
    );
    return _objectingToValuesWarning;
  }

  getCouncilRatesNotice(councilId) {
    let councilRatesNotice = _councilRatesNotice.filter(
      (x) => x.councilId === councilId
    );
    if (councilRatesNotice && councilRatesNotice.length > 0) {
      // we will only return the html content part
      return _councilRatesNotice[0].councilRatesNotice;
    }

    // get the array of all councils rates notices from session
    let objectInSession = retrieveItem(constants.COUNCIL_RATES_NOTICE);

    // check if we have item in array for this particular councilId
    if (objectInSession && objectInSession.length > 0) {
      let councilRatesNotice = objectInSession.filter(
        (x) => x.councilId === councilId
      );
      if (councilRatesNotice) {
        // assigning the entire object
        _councilRatesNotice = councilRatesNotice;
      }
    }

    // we will only return the html content part
    return _councilRatesNotice && _councilRatesNotice.length > 0
      ? _councilRatesNotice[0].councilRatesNotice
      : null;
  }

  getPropertyVerificationMainText() {
    // first check in flux store
    if (_propertyVerificationMainText) {
      return _propertyVerificationMainText;
    }

    _propertyVerificationMainText = retrieveItem(
      constants.PROPERTY_VERIFICATION_MAIN_TEXT
    );
    return _propertyVerificationMainText;
  }
}

// we need to instantiate the store
const store = new CommonStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SET_LANDING_PAGE_TEXT:
      _landingPageText = action.landingPageText;
      removeItem(constants.LANDING_PAGE_TEXT);
      storeItem(_landingPageText, constants.LANDING_PAGE_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_PROPERTY_VERIFICATION_MODAL_TEXT:
      _propertyVerificationModalText = action.propertyVerificationModalText;
      removeItem(constants.PROPERTY_VERIFICATION_MODAL_TEXT);
      storeItem(
        _propertyVerificationModalText,
        constants.PROPERTY_VERIFICATION_MODAL_TEXT
      );
      store.emitChange();
      break;

    case actionTypes.SET_OBJECTING_TO_VALUES_TEXT:
      _objectingToValuesText = action.objectingToValuesText;
      removeItem(constants.OBJECTING_TO_VALUES_TEXT);
      storeItem(_objectingToValuesText, constants.OBJECTING_TO_VALUES_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_OBJECTION_COMMENTS_TEXT:
      _objectionCommentsText = action.objectionCommentsText;
      removeItem(constants.OBJECTON_COMMENTS_TEXT);
      storeItem(_objectionCommentsText, constants.OBJECTON_COMMENTS_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_PROPERTY_DETAILS_TEXT:
      _propertyDetailsText = action.propertyDetailsText;
      removeItem(constants.PROPERTY_DETAILS_TEXT);
      storeItem(_propertyDetailsText, constants.PROPERTY_DETAILS_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_SALES_EVIDENCE_TEXT:
      _salesEvidenceText = action.salesEvidenceText;
      removeItem(constants.SALES_EVIDENCE_TEXT);
      storeItem(_salesEvidenceText, constants.SALES_EVIDENCE_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_OBJECTING_TO_VALUES_WARNING:
      _objectingToValuesWarning = action.objectingToValuesWarning;
      removeItem(constants.OBJECTING_TO_VALUES_WARNING);
      storeItem(
        _objectingToValuesWarning,
        constants.OBJECTING_TO_VALUES_WARNING
      );
      store.emitChange();
      break;

    case actionTypes.SET_COUNCIL_RATES_NOTICE:
      _councilRatesNotice = _councilRatesNotice.filter(
        (x) => x.councilId !== action.councilId
      );
      _councilRatesNotice.push({
        councilId: action.councilId.toString(),
        councilRatesNotice: action.councilRatesNotice,
      });
      removeItem(constants.COUNCIL_RATES_NOTICE);
      storeItem(_councilRatesNotice, constants.COUNCIL_RATES_NOTICE);
      store.emitChange();
      break;

    case actionTypes.SET_PROPERTY_VERIFICATION_MAIN_TEXT:
      _propertyVerificationMainText = action.propertyVerificationMainText;
      removeItem(constants.PROPERTY_VERIFICATION_MAIN_TEXT);
      storeItem(
        _propertyVerificationMainText,
        constants.PROPERTY_VERIFICATION_MAIN_TEXT
      );
      store.emitChange();
      break;

    default:
      break;
  }
});

export default store;
