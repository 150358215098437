import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";

export async function getLandingPageText() {
  return getPageContentBySlug('adelaide_obj_pagetext-landing');
}

export function dispatchLandingPageText(landingPageText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_LANDING_PAGE_TEXT,
    landingPageText: landingPageText,
  });
}

export function getPropertyVerificationModalText() {
  return getPageContentBySlug('adelaide-obj-out-of-time-modal');
}

export function dispatchPropertyVerificationModalText(
  propertyVerificationModalText
) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_PROPERTY_VERIFICATION_MODAL_TEXT,
    propertyVerificationModalText: propertyVerificationModalText,
  });
}

export function getObjectingToValuesText() {
  return getPageContentBySlug(
    'adelaide-obj-helper-objecting-to-values-text',
    dispatchObjectingToValuesText);
}

export function dispatchObjectingToValuesText(objectingToValuesText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_OBJECTING_TO_VALUES_TEXT,
    objectingToValuesText: objectingToValuesText,
  });
}

export function getObjectionCommentsText() {
  return getPageContentBySlug(
    'adelaide-obj-helper-objection-comment', 
    dispatchObjectionCommentsText);
}

export function dispatchObjectionCommentsText(objectionCommentsText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_OBJECTION_COMMENTS_TEXT,
    objectionCommentsText: objectionCommentsText,
  });
}

export function getPropertyDetailsText() {
  return getPageContentBySlug(
    'adelaide_obj_helpertext-property-details',
    dispatchPropertyDetailsText);
}

export function dispatchPropertyDetailsText(propertyDetailsText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_PROPERTY_DETAILS_TEXT,
    propertyDetailsText: propertyDetailsText,
  });
}

export function getSalesEvidenceText() {
  return getPageContentBySlug(
    'adelaide_obj_helpertext-rental-evidence',
    dispatchSalesEvidenceText);
}

export function dispatchSalesEvidenceText(salesEvidenceText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_SALES_EVIDENCE_TEXT,
    salesEvidenceText: salesEvidenceText,
  });
}

export function getObjectingToValuesWarning() {
  // TODO Should this be Adelaide?
  return getPageContentBySlug(
    'vic_obj_warning-blank-values',
    dispatchObjectingToValuesWarning);
}

export function dispatchObjectingToValuesWarning(objectingToValuesWarning) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_OBJECTING_TO_VALUES_WARNING,
    objectingToValuesWarning: objectingToValuesWarning,
  });
}

function getPageContentBySlug(slug, dispatchTo) {
  const url =
    new URL('/pages.json', process.env.REACT_APP_CONTENT_URL).href;

  return fetch(url)
    .then(response => response.json())
    .then(json => {
      let content;
      json.forEach(x => {
          if (x.slug === slug) {
            content = x.content.rendered;
            if (dispatchTo) {
              dispatchTo(content);
            }
            return false;
          }
          return true;
      });
      return content;
    })
    .catch(error => console.error(error));
}

export function getCouncilRatesNotice(lgaCode) {
  const url =
    new URL("/lga-ratenotice-example.json", process.env.REACT_APP_CONTENT_URL).href;

  return fetch(url)
    .then(response => response.json())
    .then(json => {
        let content = null;
        json.forEach(x => {
          if (x.lga_code===lgaCode) {
            content = x.content.rendered;
            dispatchCouncilRateNotice(lgaCode, content);
            return false;
          }
        });
        return content;
    })
    .catch(error => console.error(error));
}

export function dispatchCouncilRateNotice(councilId, councilRatesNotice) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_COUNCIL_RATES_NOTICE,
    councilId: councilId,
    councilRatesNotice: councilRatesNotice,
  });
}

export function getPropertyVerificationMainText() {
  return getPageContentBySlug(
    'adelaide-obj-propverify-main-instruction-text',
    dispatchPropertyVerificationMainText);
}

export function dispatchPropertyVerificationMainText(
  propertyVerificationMainText
) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_PROPERTY_VERIFICATION_MAIN_TEXT,
    propertyVerificationMainText: propertyVerificationMainText,
  });
}
