const leaseTerms = [
  { id: "-1", name: "Please select the lease term" },
  { id: "1", name: "< 6 Months" },
  { id: "2", name: "12 Months" },
  { id: "3", name: "24 Months" },
  { id: "4", name: "36 Months" },
  { id: "5", name: "5 Years" },
  { id: "6", name: "10 Years" },
  { id: "7", name: "15 Years" },
  { id: "8", name: "> 30 Years" },
];

export default leaseTerms;
