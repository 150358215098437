import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import PropertyStore from "../../stores/propertyStore";
import ModalPage from "../../controls/MDBReact-Modal";
import ObjectorStore from "../../stores/objectorStore";
import { getNamefromList } from "../../utilities/getNameFromList";
import Councils from "../../Lists/Councils";
import RespondentTypes from "../../Lists/RespondentTypes";
import Professions from "../../Lists/Professions";
import ObjectionReasons from "../../Lists/ObjectionReasons";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import * as PropertyActions from "../../actions/propertyActions";
import SalesEvidenceStore from "../../stores/salesEvidenceStore";
import Steppers from "../../controls/Steppers/Steppers";
import ReviewPropertyDetails from "./ReviewPropertyDetails/ReviewPropertyDetails";
import ReviewLeaseDetails from "./ReviewLeaseDetails/ReviewLeaseDetails";
import ReviewSalesEvidence from "./ReviewSalesEvidence/ReviewSalesEvidence";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import { isEmailLink } from "../../utilities/isEmailLink";
import "./ReviewSubmission.scss";
import { ScrollTo } from "../../utilities/focusErrorControl";
import Alert from  "@material-ui/lab/Alert";

function ReviewSubmission(props) {
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }

  //#region verifying if the loading of this component was using
  // routing or Did user entered a URL and opened this page

  var objector = ObjectorStore.getObjector();
  var propertyId = null;
  if (!objector || !objector.objectorId) {
    props.history &&
      props.history.push({
        pathname: "/ObjectorDetails",
      });
  } else {
    // we came here means, we do have an Objector in flux / session
    // now check if we have a propertyId
    if (props && props.location && props.location.propertyId) {
      propertyId = props.location.propertyId;
    } else {
      props.history &&
        props.history.push({
          pathname: "/PropertyIdentification",
          objectorId: objector.objectorId,
        });
    }
  }
  //#endregion verifying the loading of this component
  const [errorMessage, setErrorMessage] = useState(null);
  const RESPONDENT_TYPE_AGENT = "Agent";
  const [agreed, setAgreed] = useState(false);
  const Objection_Reason_Land_Not_Correct = "2";
  const [property, setProperty] = useState(
    PropertyStore.getPropertyById(propertyId)
  );
  const [modalProps, setModalProps] = useState({
    showModal: false,
    header: "Confirmation",
    message: "",
  });
  const [salesEvidences, setSalesEvidences] = useState(
    SalesEvidenceStore.getSalesEvidenceByPropertyId(property.propertyId)
  );
  //#endregion component declarations

  //#region useEffect
  useEffect(() => {
    PropertyStore.addChangeListener(onchange);

    document.title = "Object to Rating Valuation - Review and Submit";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    function onchange() {
      PropertyStore.getPropertyById(property.propertyId);
    }

    return () => PropertyStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, [property.propertyId]);

  useEffect(() => {
    SalesEvidenceStore.addChangeListener(onchange);

    function onchange() {
      setSalesEvidences(
        SalesEvidenceStore.getSalesEvidenceByPropertyId(property.propertyId)
      );
    }

    return () => SalesEvidenceStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, [property.propertyId]);
  //#endregion useEffect

  function handleSubmit() {
    const GENERAL_ERROR = 'Objection lodgement failed. Click Back then Next to reload the step and try again. If the problem persists, contact the council on your notice and lodge your objection directly with the rates team.';

    startLoader();

    let column = "status";
    let value = "submitted";
    setProperty({ ...property, [column]: value });

    PropertyActions.submitProperty(property).then(response => {
      if (response && response.errors && response.errors.length > 0) {
        showError(GENERAL_ERROR);
        stopLoader();
        return;
      } else {
        stopLoader();
        showModal(true, "Confirmation", "Property has been submitted.");
        setTimeout(() => {
          stopLoader();

          props.history &&
            props.history.push({
              pathname: "/PropertyIdentification",
              objectorId: objector.objectorId,
            });
        }, 3000);
      }
    }, (error) => {  
      showError(GENERAL_ERROR);
      stopLoader();
      return;
    });
  }

  function showError(message) {
    setErrorMessage(message);
    let element = document.getElementById("submissionError");
    if (element) {
      ScrollTo(element);
    }
  }

  function checkboxChanged() {
    setAgreed(!agreed);
  }

  function goToSalesEvidence() {
    // we need to check if, the objection reason selected is
    // Objection_Reason_Land_Not_Correct !== "2", if yes, go to Rental evidence
    let previousRoute = "/ObjectionDetails";
    if (property.objectionReasonId !== Objection_Reason_Land_Not_Correct) {
      previousRoute = "/RentalEvidence";
    }
    props.history &&
      props.history.push({
        pathname: previousRoute,
        propertyId: property.propertyId,
        objectorId: objector.objectorId,
      });
  }

  //#region Modal handler
  function showModal(_showModal, _header, _message) {
    setModalProps({
      showModal: _showModal,
      header: _header,
      message: _message,
    });

    // remove the modal after 3 seconds
    setTimeout(() => {
      setModalProps({
        showModal: false,
        header: "",
        message: "",
      });
    }, 3000);
  }

  function modalToggled() {
    setModalProps({
      showModal: false,
      header: "",
      message: "",
    });
  }
  //#endregion Modal handler

  return (
    <>
      <MDBContainer className="review-page-font">
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <h4>{property.address}</h4>
            <h5>{getNamefromList(Councils, property.councilId)}</h5>
            <h5>Assessment Number: {property.assessmentNumber}</h5>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="12" lg="12" md="12" sm="12" className="mb-3">
            <div id="submissionError"> 
            { errorMessage && 
              <Alert severity="error">
                { errorMessage }
              </Alert>
            }
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-3">
          <MDBCol className="review-section-headings">Objector Details</MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Objector</span>
              <span className="review-page-field-value">
                {objector.firstName + " " + objector.lastName}
              </span>
            </div>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Postal address</span>
              <span className="review-page-field-value">
                {objector.postalAddress +
                  " " +
                  objector.suburb +
                  " " +
                  objector.state +
                  " " +
                  objector.postcode}
              </span>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Respondent type</span>
              <span className="review-page-field-value">
                {getNamefromList(RespondentTypes, objector.respondantType)}
              </span>
            </div>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Email</span>
              <span className="review-page-field-value">{objector.email}</span>
            </div>
          </MDBCol>
        </MDBRow>

        {objector.respondantType === RESPONDENT_TYPE_AGENT && (
          <>
            <MDBRow>
              {objector.professionId !== "-1" &&
                objector.professionId !== "" &&
                objector.professionId !== null && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Profession
                      </span>
                      <span className="review-page-field-value">
                        {getNamefromList(Professions, objector.professionId)}
                      </span>
                    </div>
                  </MDBCol>
                )}
              {objector.companyName !== "" && objector.companyName !== null && (
                <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                  <div className="review-page-field">
                    <span className="review-page-field-heading">
                      Company name
                    </span>
                    <span className="review-page-field-value">
                      {objector.companyName}
                    </span>
                  </div>
                </MDBCol>
              )}
            </MDBRow>
          </>
        )}
        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Phone</span>
              <span className="review-page-field-value">{objector.mobile}</span>
            </div>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6"></MDBCol>
        </MDBRow>

        <MDBRow className="mb-3">
          <MDBCol className="review-section-headings">
            Grounds for Objecting
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">
                Objection Grounds
              </span>
              <span className="review-page-field-value">
                {getNamefromList(ObjectionReasons, property.objectionReasonId)}
              </span>
            </div>
          </MDBCol>

          {property.objectionComment !== null &&
            property.objectionComment !== "" && (
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <div className="review-page-field">
                  <span className="review-page-field-heading">
                    Objection comments
                  </span>
                  <span className="review-page-field-value">
                    {property.objectionComment}
                  </span>
                </div>
              </MDBCol>
            )}
        </MDBRow>

        {((property.netAnnualValue !== null &&
          property.netAnnualValue !== "" &&
          property.netAnnualValue !== "0") ||
          (property.contendedNetAnnualValue !== null &&
            property.contendedNetAnnualValue !== "" &&
            property.contendedNetAnnualValue !== "0")) && (
          <>
            <MDBRow>
              {property.netAnnualValue !== null &&
                property.netAnnualValue !== "" &&
                property.netAnnualValue !== "0" && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Annual value
                      </span>
                      <span className="review-page-field-value">
                        {"$" + property.netAnnualValue}
                      </span>
                    </div>
                  </MDBCol>
                )}
              {property.contendedNetAnnualValue !== null &&
                property.contendedNetAnnualValue !== "" &&
                property.contendedNetAnnualValue !== "0" && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Contended Annual Value
                      </span>
                      <span className="review-page-field-value">
                        {"$" + property.contendedNetAnnualValue}
                      </span>
                    </div>
                  </MDBCol>
                )}
            </MDBRow>
          </>
        )}

        <ReviewPropertyDetails property={property}></ReviewPropertyDetails>

        <ReviewLeaseDetails property={property}></ReviewLeaseDetails>

        <ReviewSalesEvidence
          salesEvidences={salesEvidences}
          landAreaUnitID={property.landAreaUnitID}
        ></ReviewSalesEvidence>

        <MDBRow className="mb-3">
          <MDBCol>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="defaultUnchecked"
                onChange={checkboxChanged}
                checked={agreed}
                aria-labelledby="objection-terms-agreed"
              />
              <label
                id="objection-terms-agreed"
                aria-label="objection-terms-agreed"
                className="custom-control-label"
                htmlFor="defaultUnchecked"
              >
                <p>
                  I believe the above details to be true and correct to the best
                  of my knowledge.{" "}
                </p>
                <p>
                  A person who, without reasonable excuse fails to make a return
                  of information as required under Local Government Act 1999
                  Section 168 4 (c) or furnishes a return that is false or
                  misleading in a material particular is guilty of an offence.
                  Maximum Penalty $5,000.
                </p>
              </label>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3">
          <MDBCol>
            <div className="float-left" id="backButton">
              <MDBBtn
                aria-labelledby="backButton"
                className="button-style"
                onClick={goToSalesEvidence}
              >
                Back
              </MDBBtn>
            </div>
            <div className="float-right" id="submitButton">
              <MDBBtn
                aria-labelledby="submitButton"
                className="button-style"
                disabled={!agreed}
                onClick={handleSubmit}
              >
                Submit
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
        {modalProps.showModal && (
          <ModalPage
            header={modalProps.header}
            showModal={modalProps.showModal}
            message={modalProps.message}
            modalToggled={modalToggled}
          />
        )}
      </MDBContainer>
    </>
  );
}

export default ReviewSubmission;
