const dwellingTypes = [
  { id: "-1", name: "Please select improvement type" },
  { id: "7", name: "Ancillary Buildings/Sheds" },
  { id: "4", name: "Apartment" },
  { id: "2", name: "Attached dwelling" },
  { id: "1", name: "Detached dwelling" },
  { id: "8", name: "Education Facility" },
  { id: "9", name: "Factory" },
  { id: "10", name: "Farm (Dwelling)" },
  { id: "11", name: "Grain Store" },
  { id: "12", name: "Hardstand" },
  { id: "13", name: "Livestock Sheds" },
  { id: "14", name: "Medical Centre" },
  { id: "15", name: "Retail Shop" },
  { id: "16", name: "Store" },
  { id: "5", name: "Townhouse" },
  { id: "6", name: "Vacant Land" },
  { id: "3", name: "Villa unit" },
  { id: "17", name: "Warehouse" },
  { id: "18", name: "Other" },
];

export default dwellingTypes;
