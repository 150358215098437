import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";

const CHANGE_EVENT = "change";
let _breadcrumbRoutes = [
  {
    id: 0,
    name: "Objection Portal",
    to: "/",
    desc: "Objection Portal",
    enabled: true,
    isSelected: true,
    isVisible: true,
  },
  {
    id: 1,
    name: "Your Details",
    to: "/ObjectorDetails",
    desc: "Your Details",
    enabled: false,
    isSelected: false,
    isVisible: true,
  },
  {
    id: 2,
    name: "Property Identification",
    desc: "Property Identification",
    to: "/PropertyIdentification",
    enabled: false,
    isSelected: false,
    isVisible: true,
  },
  {
    id: 3,
    name: "Objection Details",
    to: "/ObjectionDetails",
    desc: "Objection Details",
    enabled: false,
    isSelected: false,
    isVisible: true,
  },
  {
    id: 4,
    name: "Rental Evidence",
    to: "/RentalEvidence",
    desc: "Rental Evidence",
    enabled: false,
    isSelected: false,
    isVisible: true,
  },
  {
    id: 5,
    name: "Review and Submit",
    to: "/ReviewSubmission",
    desc: "Review and Submit",
    enabled: false,
    isSelected: false,
    isVisible: true,
  },
];

class BreadcrumbStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getBreadcrumbRoutes() {
    return _breadcrumbRoutes;
  }

  getVisibeBreadcrumbRouters() {
    // for ADELAIDE, WE HAVE SOME ROUTES THAT MAY BE VISIBLE ON CONDITION
    // SO WHENEVER WE RETURN, WE CHECK FOR isVisible
    return _breadcrumbRoutes.filter((x) => x.isVisible === true);
  }
}

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.CHANGE_ROUTE_VISIBILITY:
      let breadcrumbRoute = _breadcrumbRoutes.filter((x) => x.id === action.id);
      breadcrumbRoute[0].isVisible = action.visible;
      let index = _breadcrumbRoutes.findIndex((x) => x.id === action.id);
      _breadcrumbRoutes.splice(index, 1, breadcrumbRoute[0]);
      store.emitChange();
      break;

    case actionTypes.IS_ROUTING:
      // this is just to trigger onChange of Header.js
      store.emitChange();
      break;

    case actionTypes.CURRENT_ROUTE:
      let currentRouteId = 0;
      _breadcrumbRoutes.forEach((item) => {
        if (item.to === action.route) {
          item.isSelected = true;
          currentRouteId = item.id;
        } else {
          item.isSelected = false;
        }
      });

      _breadcrumbRoutes.forEach((item) => {
        if (item.id <= currentRouteId) {
          item.enabled = true;
        } else {
          item.enabled = false;
        }
      });

      store.emitChange();
      break;

    default:
      //do nothing
      break;
  }
});

// we need to instantiate the store
const store = new BreadcrumbStore();

export default store;
