import ObjectorHelper from "../FieldHelper/objectorHelper";
import ObjectionDetailsHelper from "../FieldHelper/objectionDetailsHelper";
import PropertyDetailsHelper from "../FieldHelper/propertyDetailsHelper";
import constants from "../components/Constants";
import PropertyIdentificationHelper from "../FieldHelper/propertyIdentificationHelper";
import SaleEvidenceHelper from "../FieldHelper/salesEvidenceHelper";
import ValidateObjectorHelper from "../FieldHelper/validateObjectorHelper";

export function fieldValidator(moduleConstant, objectToValidate, helper) {
  let isSuccess = true;
  for (const [key, value] of Object.entries(helper)) {
    let val = objectToValidate[key];
    let fieldDetails = {};

    switch (moduleConstant) {
      case constants.OBJECTOR:
        fieldDetails = ObjectorHelper.getFieldDetail(key);
        break;

      case constants.OBJECTION:
        fieldDetails = ObjectionDetailsHelper.getFieldDetail(key);
        break;

      case constants.PROJECT_DETAILS:
        fieldDetails = PropertyDetailsHelper.getFieldDetail(key);
        break;

      case constants.PROPERTY_IDENTIFICATION:
        fieldDetails = PropertyIdentificationHelper.getFieldDetail(key);
        break;

      case constants.SALE_EVIDENCE:
        fieldDetails = SaleEvidenceHelper.getFieldDetail(key);
        break;

      case constants.VALIDATE_OBJECTOR:
        fieldDetails = ValidateObjectorHelper.getFieldDetail(key);
        break;

      default:
        break;
    }

    if (
      fieldDetails.required &&
      (((!fieldDetails.type ||
        fieldDetails.type !== "select" ||
        fieldDetails.type !== "date") &&
        (!val || (val.replace && val.replace(/\s/g, "") === ""))) ||
        (fieldDetails.type === "select" && (!val || val === "-1")) ||
        (fieldDetails.type === "date" && !val))
    ) {
      value.hasError = true;
      isSuccess = false;
      if (fieldDetails.fieldName && fieldDetails.fieldName !== "") {
        value.errorText = fieldDetails.fieldName + " is required";
      }
    } else {
      value.hasError = false;
      value.errorText = "";
    }

    // checking the entered value for value type
    let isDataCorrect = isFieldTypeValid(fieldDetails, val);
    if (!isDataCorrect) {
      isSuccess = isDataCorrect;
    }
  }
  return isSuccess;
}

export function isFieldTypeValid(fieldDetails, value, isSuccess) {
  // type text means only alphabets are allowed with possible spaces
  if (fieldDetails.type === "fixedLength") {
    if (fieldDetails.length && value.length > fieldDetails.length) {
      fieldDetails.hasError = true;
      fieldDetails.errorText = `Maximum of ${fieldDetails.length.toString()} characters is allowed.`;
      return false;
    }
  } else if (fieldDetails.type === "alphanumeric") {
    let pattern = /[a-zA-Z0-9_.-\\///]*[\s]*[a-zA-Z0-9]*[\s]*[a-zA-Z0-9]*[\s]*[a-zA-Z0-9]*[\s]*[a-zA-Z0-9]/;
    if (value && value !== "") {
      let result = value.match(pattern);
      if (result[0].length < value.length) {
        fieldDetails.hasError = true;
        fieldDetails.errorText =
          fieldDetails.fieldName + " can only contain letters and numbers.";
        return false;
      }
    }
  }
  else if (fieldDetails.type === "numeric") {
    // allows for decimal
    if (value && value !== "") {
      let pattern = fieldDetails.isPositiveOnly
        ? /[0-9]+\.?[0-9]*/
        : /[-]?[0-9]+\.?[0-9]*/;
      let result = value.match(pattern);
      if (!result || result[0].length < value.length) {
        fieldDetails.hasError = true;
        fieldDetails.errorText = fieldDetails.fieldName + " should be numeric.";
        return false;
      }

      if (
        (fieldDetails.min && value < fieldDetails.min) ||
        (fieldDetails.max && value > fieldDetails.max)
      ) {
        fieldDetails.hasError = true;
        fieldDetails.errorText =
          fieldDetails.fieldName +
          " values should be between " +
          fieldDetails.min +
          " and " +
          fieldDetails.max;
        return false;
      }
    }
  } else if (fieldDetails.type === "number") {
    if (value && value !== "") {
      // if there is a decimal remove it
      let tempValue = value.split(".");
      if (tempValue && tempValue.length > 1) {
        value = tempValue[0];
      }
      // no decimal allowed , can not be negative
      let pattern = /[0-9]*/;
      let result = value.match(pattern);
      if (result[0].length < value.length) {
        fieldDetails.hasError = true;
        fieldDetails.errorText =
          fieldDetails.fieldName + " should only contain numbers.";
        return false;
      }
      // now check for min max
      if (
        (fieldDetails.min && value < fieldDetails.min) ||
        (fieldDetails.max && value > fieldDetails.max)
      ) {
        fieldDetails.hasError = true;
        fieldDetails.errorText =
          fieldDetails.fieldName +
          " values should be between " +
          fieldDetails.min +
          " and " +
          fieldDetails.max;
        return false;
      }
    }
  } else if (fieldDetails.type === "email") {
    if (value && value !== "") {
      // Basic email validation. Note that proper email validation is almost impossible
      // with a regular expression and not recommend. Basic validation is designed to catch
      // simple typos, no more.
      let pattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
      let result = pattern.test(value);
      if (!result) {
        fieldDetails.hasError = true;
        fieldDetails.errorText = fieldDetails.fieldName + " is not valid.";
        return false;
      }
    }
  }
  return true;
}

export function fieldLevelValidation(fieldDetails, value) {
  if (!fieldDetails) return;
  
  if (
    fieldDetails.required &&
    (((!fieldDetails.type || fieldDetails.type !== "select") &&
      (!value || value.replace(/\s/g, "") === "")) ||
      (fieldDetails.type === "select" && (!value || value === "-1")))
  ) {
    fieldDetails.hasError = true;
    if (fieldDetails.fieldName && fieldDetails.fieldName !== "") {
      fieldDetails.errorText = fieldDetails.fieldName + " is required";
    }
  } else {
    fieldDetails.hasError = false;
    if (fieldDetails.fieldName && fieldDetails.fieldName !== "") {
      fieldDetails.errorText = "";
    }
  }

  // checking the entered value for value type
  return isFieldTypeValid(fieldDetails, value);
}
